import { Injectable } from '@angular/core';
import { CompaniesService, Company, FindCompanyRequest } from 'ldt-moneyball-api';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyInfoService {
  private maxCacheSize = 500; // Maximum number of cached items allowed
  private companyCache: Map<string, Company> = new Map();

  constructor(
    private companiesService: CompaniesService,
    private authService: AuthService
  ) {}

  // Add the company to the cache
  private addToCache(key: string, response: any): void {
    if (this.companyCache.size >= this.maxCacheSize) {
      const firstKey = this.companyCache.keys().next().value;
      if (firstKey) {
        this.companyCache.delete(firstKey);
      }
    }
    this.companyCache.set(key, response);
  }

  public getCompanyInfo(companyId: string): Observable<Company | undefined> {
    if (this.companyCache.has(companyId)) {
      return of(this.companyCache.get(companyId));
    }

    const req: FindCompanyRequest = {
      search_value: companyId,
    };
    return this.companiesService.findCompany(this.authService.getSelectedOrgIdValue, req).pipe(
      catchError(() => {
        return of(undefined);
      }),
      map((response) => {
        if (
          response &&
          response.companies &&
          response.companies instanceof Array &&
          response.companies.length > 0
        ) {
          this.addToCache(companyId, response.companies[0]);
          return response.companies[0];
        } else {
          return undefined;
        }
      })
    );
  }
}
