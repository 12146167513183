export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',
  heapAppId: '4104449956',
  intercomAppId: 'jvtkde6p',
  moneyballSubscriptionPriceId: 'price_1QGBO2KjCB7MuRGZxaXj7T3W',
  ledgerApiBasePathV2: 'https://staging.gotlivedata.io/api/ledger/v2',
  ledgerApiBasePath: 'https://staging.gotlivedata.io/api/ledger/v1',
  identityApiBasePath: 'https://staging.gotlivedata.io/api/identity/v1',
  cleanApiBasePath: 'https://staging.gotlivedata.io/api/clean/v2',
  onboardingApiBasePath: 'https://staging.gotlivedata.io/api/onboarding/v1',
  billingApiBasePath: 'https://staging.gotlivedata.io/api/billing/v1',
  dwReaderApiBasePath: 'https://dw.dwh-pipeline.staging.gotlivedata.io/api/dw/v1',
  growApiBasePath: 'https://gotlivedata.io/api/grow',
  devDeploysApiBasePath: 'https://dw.dwh-pipeline.staging.gotlivedata.io/api/dynamo/v1',
  moneyballApiBasePath: 'https://staging.gotlivedata.io/api/moneyball/v1',
  peopleApiBasePath: 'https://staging.gotlivedata.io/api/people/v1',
  // dataDeliveryApiBasePath: 'https://staging.gotlivedata.io/api/deliveries/v1',
  dataDeliveryApiBasePath:
    'https://deliveries.dwh-pipeline.staging.gotlivedata.io/api/deliveries/v1',
  resolverApiBasePath: 'https://resolver.dwh-pipeline.staging.gotlivedata.io/api/resolver/v1',
  recaptchaV3SiteKey: '6LcUCkMeAAAAAChgVW3JuwaqVulc6BK6c7RymQGC',
  onboardingNotificationURL: 'https://b00e517e99e95689512dd34120cbcde2.m.pipedream.net',
  googleAuthClientId: '497055724954-nc1nsh88tqrqvdp8a91j22a26mtge1bo.apps.googleusercontent.com',
};
