import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  CompaniesService,
  Company,
  FindCompany200Response,
  FindCompanyRequest,
} from 'ldt-moneyball-api';
import { Observable, catchError, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationService } from '../notification-service/notification.service';
import { AuthService } from 'src/app/auth/service/auth.service';

export interface SelectedCompany {
  type: 'group' | 'company';
  company: Company;
}

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
})
export class CompanySearchComponent implements OnChanges, AfterViewInit {
  @Input() disabled: boolean = false;
  @Input() disabledText: string = 'Disabled';
  @Input() resetAfterSelection: boolean = false;
  @Input() orgId: string | null = null;
  @Input() labelText: string = 'Enter company name...';
  @Input() floatLabel: 'auto' | 'always' = 'auto'; // default to auto but can be set to always

  @ViewChild('inputField') inputField?: ElementRef;
  @ViewChild('autoInput') autocompleteTrigger!: MatAutocompleteTrigger;

  @Output() companySelected = new EventEmitter<SelectedCompany>();

  searching: boolean = false;
  companyNameControl = new FormControl<string>('');
  searchCompanies: Observable<SelectedCompany[]>;

  constructor(
    private companiesService: CompaniesService,
    private notify: NotificationService,
    private authService: AuthService
  ) {
    // If the parent component didn't provide an orgId, get it from auth service
    if (!this.orgId) {
      this.orgId = this.authService.getSelectedOrgIdValue;
    }

    // Auto-complete on company name
    this.searchCompanies = this.companyNameControl.valueChanges.pipe(
      filter((c) => c !== null && c.length >= 1), // Bump this to only search if a certain number of chars is typed
      tap(() => {
        this.searching = true;
      }),
      debounceTime(100), // When someone is typing, don't submit on every single keypress
      switchMap((c) => {
        const req: FindCompanyRequest = {
          search_value: c as string,
        };
        // Use an intermediate observable to catch the error and return undefined
        return this.companiesService.findCompany(this.orgId!, req).pipe(
          catchError(() => {
            return of(undefined);
          })
        );
      }),
      map((d: FindCompany200Response | undefined) => {
        // If there was an error searching for the company
        if (!d) {
          // this.showError("Error searching for companies. Try another search term.");
          this.searching = false;
          return [];
        }
        // We cheat with any here because the API response doesn't have a type for the company search report
        const data = d as any;
        this.searching = false;

        let searchItems: SelectedCompany[] = [];
        let companiesWithGroups = d.companies || [];

        companiesWithGroups.forEach((c: Company) => {
          searchItems.push({
            type: 'company',
            company: c,
          });
          if (c.grouped_company_count && c.grouped_company_count > 0) {
            searchItems.push({
              type: 'group',
              company: c,
            });
          } else if (c.group_id) {
            if (
              !searchItems.find(
                (s: SelectedCompany) => s.type == 'group' && s.company.id == c.group_id
              )
            ) {
              const g = d.company_groups?.find((g: Company) => g.id == c.group_id);
              if (g) {
                searchItems.push({
                  type: 'group',
                  company: g,
                });
              }
            }
          }
        });
        return searchItems;
      }),
      catchError(() => {
        // This kind of error shouldn't really happen, but if it does, this observable dies
        this.notify.error('Something unexpected happened. Please reload the page and try again');
        this.searching = false;
        return of([]);
      })
    );
  }

  ngAfterViewInit(): void {
    // setTimeout is needed to focus the input field after the view is initialized, to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.focusInputField();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.companyNameControl.disable();
        this.companyNameControl.reset(this.disabledText, { onlySelf: true, emitEvent: false });
      } else {
        this.companyNameControl.enable();
        this.companyNameControl.reset('', { onlySelf: true, emitEvent: false });
      }
    }
  }

  onCompanySelected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option) {
      console.error('No option selected');
      return;
    }
    const selectedCompany = event.option.value as SelectedCompany;
    this.toggleCompany(selectedCompany);
  }

  toggleCompany(c: SelectedCompany): void {
    this.companyNameControl.reset((c.company as Company).name, {
      onlySelf: true,
      emitEvent: false,
    });
    this.autocompleteTrigger.closePanel();

    this.companySelected.emit(c); // Emit the selected company data with identifier

    if (this.resetAfterSelection) {
      this.companyNameControl.reset('', { onlySelf: true, emitEvent: false });
    }
  }

  // focus input field when user clicks on 'Add company' button
  focusInputField() {
    if (this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }
}
