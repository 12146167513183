import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnCategory, DWColumn } from 'src/app/data-warehouse/dw-column';

interface DialogData {
  categories: ColumnCategory[];
  fields: DWColumn[];
  multi?: boolean;
  returnFields?: ReturnFields;
  disabledFields?: string[];
}

interface ReturnFields {
  [key: string]: boolean;
}

@Component({
  selector: 'app-field-selector-dialog',
  templateUrl: './field-selector-dialog.component.html',
  styleUrls: ['./field-selector-dialog.component.scss'],
  host: { class: 'field-selector-dialog-component' },
})
export class FieldSelectorDialogComponent implements OnInit {
  categories: ColumnCategory[];
  fields: DWColumn[];
  multi: boolean = false;
  returnFields: ReturnFields = {};
  filterText: string = '';
  showDBName: boolean = false;
  classicView: boolean = false;
  popularFilters: DWColumn[] = [];
  displayData: { [key: string]: DWColumn[] } = {};
  disabledFields: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<FieldSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.categories = data.categories;
    this.fields = data.fields;
    if (data.multi) {
      this.multi = data.multi;
    }
    if (data.returnFields) {
      this.returnFields = { ...data.returnFields };
    }
    if (data.disabledFields) {
      this.disabledFields = data.disabledFields;
    }
    this.popularFilters = this.fields.filter((f) => f.isPopular);
    this.loadViewPrefs();
  }

  ngOnInit(): void {
    this.formatData();
  }

  formatData() {
    const grouped = this.fields.reduce(
      (map, f) => ({
        ...map,
        [f.group || 'default']: [...(map[f.group || 'default'] ?? []), f],
      }),
      {} as any
    );

    this.displayData = grouped;
  }

  // count filtered items
  getFilteredTotal() {
    return Object.values(this.displayData)
      .flat()
      .filter((col) => col.displayName.includes(this.filterText)).length;
  }

  getCheckedCount(group?: string) {
    let count = 0;
    this.fields.forEach((f) => {
      if ((!group || f.group === group) && this.returnFields[f.name]) {
        count++;
      }
    });
    return count;
  }

  getTotalCount(): number {
    return Object.values(this.displayData)
      .map((group) => group?.length || 0)
      .reduce((a, b) => a + b, 0);
  }

  isFieldDisabled(fieldName: string): boolean {
    return this.disabledFields.includes(fieldName);
  }

  selectField(name: string) {
    this.saveViewPrefs();
    this.dialogRef.close(name);
  }

  toggleField(fieldName: string) {
    this.returnFields[fieldName] = !this.returnFields[fieldName];
  }

  saveSelections() {
    this.saveViewPrefs();
    this.dialogRef.close(this.returnFields);
  }

  saveViewPrefs() {
    localStorage.setItem(
      'dwFieldSelectorPrefs',
      JSON.stringify({ classicView: this.classicView, showDBName: this.showDBName })
    );
  }

  loadViewPrefs() {
    const viewPrefs = JSON.parse(localStorage.getItem('dwFieldSelectorPrefs') || '{}');
    this.classicView = viewPrefs.classicView || false;
    this.showDBName = viewPrefs.showDBName || false;
  }

  cancel() {
    this.dialogRef.close();
  }

  selectAll(group: string) {
    this.fields.forEach((f) => {
      if (f.group === group) {
        this.returnFields[f.name] = true;
      }
    });
  }

  deselectAll(group: string) {
    this.fields.forEach((f) => {
      if (f.group === group) {
        this.returnFields[f.name] = false;
      }
    });
  }

  clearFilter(): void {
    this.filterText = '';
  }

  getCategoriesForColumn(columnNumber: number): ColumnCategory[] {
    return this.categories.filter((c) => c.displayColumn === columnNumber);
  }

  getAllCategoryColumns(): number[] {
    return Array.from(new Set(this.categories.map((c) => c.displayColumn)));
  }
}
