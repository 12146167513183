import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import {
  UpdateOrgSettingsRequestSettings,
  UpdateOrgSettingsRequestSettingsMoneyball,
} from 'ldt-identity-service-api';

type MoneyballSubscriptionType = 'unlimited' | 'licensed' | 'trial';

@Component({
  selector: 'app-org-settings-editor',
  templateUrl: './org-settings-editor.component.html',
  styleUrls: ['./org-settings-editor.component.scss'],
})
export class OrgSettingsEditorComponent {
  @Input() usePartialUpdate?: boolean = false;
  @Input() initialSettings?: {
    [key: string]: any;
  };

  subscriptionTypes: { value: MoneyballSubscriptionType; label: string }[] = [
    { value: 'unlimited', label: 'Unlimited' },
    { value: 'licensed', label: 'Licensed' },
    { value: 'trial', label: 'Trial' },
  ];

  form: FormGroup;
  settings: {
    [key: string]: any;
  } = {};
  minExpirationDate: Date;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      contactLimit: [''],
      jobsCapability: [false],
      moneyballCapability: [false],
      moneyballExpiration: [''],
      ledgerReinit: [false],
      ledgerCapability: [false],
      peopleCapability: [false],
      ledgerUiApiVersion: ['2'],
      ledgerUiApiVersionSelector: [false],
      moneyballSubscriptionType: ['unlimited'],
      moneyballEntitledSeats: [0],
      shouldIncludeContactLimit: [true],
      shouldIncludeJobsCapability: [true],
      shouldIncludeMoneyballCapability: [true],
      shouldIncludeMoneyballExpiration: [true],
      useStripeForMoneyballExpiration: [false],
      shouldIncludeLedgerReinit: [true],
      shouldIncludeLedgerCapability: [true],
      shouldIncludePeopleCapability: [true],
      shouldIncludeLedgerUiApiVersion: [true],
      shouldIncludeLedgerUiApiVersionSelector: [true],
      shouldIncludeMoneyballSubscriptionType: [true],
      shouldIncludeMoneyballEntitledSeats: [true],
    });

    this.form.get('moneyballExpiration')?.valueChanges.subscribe((newDate) => {
      this.onMBExpirationDateChange(newDate);
    });

    this.form.get('moneyballCapability')?.valueChanges.subscribe((mbEnabled) => {
      this.onMBCapabilityChange(mbEnabled);
    });

    this.minExpirationDate = new Date();
  }

  ngOnInit(): void {
    this.settings = this.initialSettings || {};

    // Initialize form values with defaults
    const formValues: any = {
      contactLimit: '',
      jobsCapability: false,
      ledgerReinit: false,
      moneyballCapability: false,
      moneyballExpiration: '',
      ledgerCapability: false,
      peopleCapability: false,
      ledgerUiApiVersion: false,
      ledgerUiApiVersionSelector: false,
      moneyballSubscriptionType: 'unlimited',
      moneyballEntitledSeats: 0,
    };

    // Initialize all shouldInclude flags to false when using partial update
    if (this.usePartialUpdate) {
      Object.keys(this.form.controls)
        .filter((key) => key.startsWith('shouldInclude'))
        .forEach((key) => {
          formValues[key] = false;
        });
    }

    // Process moneyball expiration date if it exists
    let mbExpirationDate = null;
    if (this.settings.moneyball?.expiresAt) {
      mbExpirationDate = dayjs(this.settings.moneyball.expiresAt, 'MM/DD/YYYY').toDate();
      formValues.moneyballExpiration = mbExpirationDate;
      if (this.usePartialUpdate) {
        if (this.settings.moneyball.expiresAt === '{{expiresAt}}') {
          formValues.useStripeForMoneyballExpiration = true;
          formValues.moneyballExpiration = '';
        }
        formValues.shouldIncludeMoneyballExpiration = true;
      }
    }

    // Map settings to form values and set shouldInclude flags
    if (this.settings.ledger?.contactLimit !== undefined) {
      formValues.contactLimit = this.settings.ledger.contactLimit;
      if (this.usePartialUpdate) formValues.shouldIncludeContactLimit = true;
    }

    if (this.settings.clean?.capability !== undefined) {
      formValues.jobsCapability = this.settings.clean.capability;
      if (this.usePartialUpdate) formValues.shouldIncludeJobsCapability = true;
    }

    if (this.settings.ledger?.allowReinit !== undefined) {
      formValues.ledgerReinit = this.settings.ledger.allowReinit;
      if (this.usePartialUpdate) formValues.shouldIncludeLedgerReinit = true;
    }

    if (this.settings.moneyball?.capability !== undefined) {
      formValues.moneyballCapability = this.settings.moneyball.capability;
      if (this.usePartialUpdate) formValues.shouldIncludeMoneyballCapability = true;
    }

    if (this.settings.ledger?.capability !== undefined) {
      formValues.ledgerCapability = this.settings.ledger.capability;
      if (this.usePartialUpdate) formValues.shouldIncludeLedgerCapability = true;
    }

    if (this.settings.people?.capability !== undefined) {
      formValues.peopleCapability = this.settings.people.capability;
      if (this.usePartialUpdate) formValues.shouldIncludePeopleCapability = true;
    }

    if (this.settings.ledger?.uiApiVersion !== undefined) {
      formValues.ledgerUiApiVersion = this.settings.ledger.uiApiVersion === '2';
      if (this.usePartialUpdate) formValues.shouldIncludeLedgerUiApiVersion = true;
    }

    if (this.settings.ledger?.uiApiVersionSelector !== undefined) {
      formValues.ledgerUiApiVersionSelector = this.settings.ledger.uiApiVersionSelector;
      if (this.usePartialUpdate) formValues.shouldIncludeLedgerUiApiVersionSelector = true;
    }

    if (this.settings.moneyball?.subType !== undefined) {
      formValues.moneyballSubscriptionType = this.settings.moneyball.subType;
      if (this.usePartialUpdate) formValues.shouldIncludeMoneyballSubscriptionType = true;
    }

    if (this.settings.moneyball?.licensedUsers !== undefined) {
      formValues.moneyballEntitledSeats = this.settings.moneyball.licensedUsers;
      if (this.usePartialUpdate) formValues.shouldIncludeMoneyballEntitledSeats = true;
    }

    this.form.patchValue(formValues);
  }

  getCurrentSettings() {
    let mbExpirationDate = '';
    if (this.form.get('moneyballExpiration')?.value) {
      mbExpirationDate = dayjs(this.form.get('moneyballExpiration')?.value).format('YYYY-MM-DD');
    }

    let mbSubType = UpdateOrgSettingsRequestSettingsMoneyball.SubTypeEnum.Unlimited;
    if (this.form.get('moneyballSubscriptionType')?.value === 'licensed') {
      mbSubType = UpdateOrgSettingsRequestSettingsMoneyball.SubTypeEnum.Licensed;
    } else if (this.form.get('moneyballSubscriptionType')?.value === 'trial') {
      mbSubType = UpdateOrgSettingsRequestSettingsMoneyball.SubTypeEnum.Trial;
    }

    // Helper function to convert to integer
    const toPositiveInteger = (value: any): string | number | undefined => {
      if (value === '' || value === null || value === undefined) {
        return undefined;
      }
      if (typeof value === 'string') {
        return value;
      }
      const num = parseInt(value, 10);
      return isNaN(num) ? undefined : Math.max(0, num);
    };

    // We can't use the defined type for settings because we need to allow strings
    // for any value that is a template string
    let settings: UpdateOrgSettingsRequestSettings | any = {
      clean: {
        capability: this.form.get('shouldIncludeJobsCapability')?.value
          ? this.form.get('jobsCapability')?.value
          : undefined,
      },
      ledger: {
        capability: this.form.get('shouldIncludeLedgerCapability')?.value
          ? this.form.get('ledgerCapability')?.value
          : undefined,
        allowReinit: this.form.get('shouldIncludeLedgerReinit')?.value
          ? this.form.get('ledgerReinit')?.value
          : undefined,
        contactLimit: this.form.get('shouldIncludeContactLimit')?.value
          ? toPositiveInteger(this.form.get('contactLimit')?.value)
          : undefined,
        uiApiVersion: this.form.get('shouldIncludeLedgerUiApiVersion')?.value
          ? this.form.get('ledgerUiApiVersion')?.value
            ? '2'
            : '1'
          : undefined,
        uiApiVersionSelector: this.form.get('shouldIncludeLedgerUiApiVersionSelector')?.value
          ? this.form.get('ledgerUiApiVersionSelector')?.value
            ? true
            : false
          : undefined,
      },
      moneyball: {
        capability: this.form.get('shouldIncludeMoneyballCapability')?.value
          ? this.form.get('moneyballCapability')?.value
          : undefined,
        expiresAt: this.form.get('shouldIncludeMoneyballExpiration')?.value
          ? this.form.get('useStripeForMoneyballExpiration')?.value
            ? '{{expiresAt}}'
            : mbExpirationDate
          : undefined,
        subType: this.form.get('shouldIncludeMoneyballSubscriptionType')?.value
          ? mbSubType
          : undefined,
        licensedUsers: this.form.get('shouldIncludeMoneyballEntitledSeats')?.value
          ? toPositiveInteger(this.form.get('moneyballEntitledSeats')?.value)
          : undefined,
      },
      people: {
        capability: this.form.get('shouldIncludePeopleCapability')?.value
          ? this.form.get('peopleCapability')?.value
          : undefined,
      },
    };

    return settings;
  }

  private onMBExpirationDateChange(event: any) {
    if (event) {
      this.form.get('moneyballCapability')?.setValue(true);
    }
  }

  private onMBCapabilityChange(event: any) {
    if (!event) {
      this.form.get('moneyballExpiration')?.setValue('');
    }
  }

  clearExpiration() {
    this.form.get('moneyballExpiration')?.setValue('');
  }
}
