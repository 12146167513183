import { Component } from '@angular/core';
import { SubscriptionsService, Subscription } from 'ldt-billing-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class AdminBillingSubscriptionsComponent {
  refreshing: boolean = false;
  rowData: Subscription[] = [];

  constructor(
    private subscriptionsService: SubscriptionsService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getSubscriptions();
  }

  public colDefsTypes: ColDef[] = [
    { field: 'org.id', maxWidth: 60 },
    { field: 'org.name', floatingFilter: true },
    { field: 'product.id' },
    { field: 'product.priceId' },
    { field: 'endsAt' },
    { field: 'status' },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  getSubscriptions(): void {
    this.refreshing = true;
    this.subscriptionsService.getSubscriptions().subscribe({
      next: (data: any) => {
        this.rowData = data.subscriptions;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('Error fetching subscriptions');
        this.refreshing = false;
      },
    });
  }
}
