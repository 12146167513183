// this is a custom alert component that supports the following types:
// normal, info, error, warning, success
// it also supports a visible input to conditionally render the alert
// it also supports a complex message with html content
// it also supports a closeable input to optionally close the alert

import { Component, Input } from '@angular/core';

export enum AlertType {
  Normal = 'normal',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

@Component({
  selector: 'app-alert',
  template: `
    <div *ngIf="visible" [ngClass]="alertClasses" class="tw-border tw-border-solid tw-rounded tw-relative tw-pl-4"
         [class.tw-p-4]="style === 'normal'"
         [class.tw-p-2]="style === 'compact'">
      <div [ngClass]="closeable ? 'tw-mr-10' : ''" class="tw-flex tw-items-center">
        <mat-icon [ngClass]="iconClass" class="tw-shrink-0 tw-mr-4">{{ icon }}</mat-icon>
        <span [ngClass]="textClass">
          <ng-content></ng-content>
        </span>
      </div>
      <button *ngIf="closeable" mat-icon-button class="tw-absolute tw-top-1 tw-right-[6px] tw-text-gray-500 dark:tw-text-gray-400" (click)="visible = false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
})
export class AlertComponent {
  @Input() alertType: AlertType = AlertType.Normal;
  @Input() visible: boolean = true;
  @Input() closeable: boolean = false;
  @Input() style: 'normal' | 'compact' = 'normal';

  get alertClasses() {
    const classes = {
      normal: 'tw-bg-gray-100 dark:tw-bg-gray-700 tw-border-gray-300 dark:tw-border-gray-500',
      info: 'tw-bg-blue-100 dark:tw-bg-blue-900/20 tw-border-blue-300 dark:tw-border-blue-500',
      error: 'tw-bg-red-100 dark:tw-bg-red-900/20 tw-border-red-300 dark:tw-border-red-500',
      warning:
        'tw-bg-orange-100 dark:tw-bg-orange-900/20 tw-border-orange-300 dark:tw-border-orange-500',
      success:
        'tw-bg-green-100 dark:tw-bg-green-900/20 tw-border-green-300 dark:tw-border-green-500',
    };

    return classes[this.alertType];
  }

  get iconClass() {
    const classes = {
      normal: 'tw-text-gray-500 dark:tw-text-gray-400',
      info: 'tw-text-blue-500 dark:tw-text-blue-400',
      error: 'tw-text-red-500 dark:tw-text-red-400',
      warning: 'tw-text-orange-500 dark:tw-text-orange-400',
      success: 'tw-text-green-500 dark:tw-text-green-400',
    };

    return classes[this.alertType];
  }

  get textClass() {
    const classes = {
      normal: 'tw-text-gray-800 dark:tw-text-gray-200',
      info: 'tw-text-blue-800 dark:tw-text-blue-200',
      error: 'tw-text-red-800 dark:tw-text-red-200',
      warning: 'tw-text-orange-800 dark:tw-text-orange-300',
      success: 'tw-text-green-800 dark:tw-text-green-200',
    };

    return classes[this.alertType];
  }

  get icon() {
    const icons = {
      normal: 'notifications',
      info: 'info',
      error: 'error',
      warning: 'warning',
      success: 'check_circle',
    };

    return icons[this.alertType];
  }
}
