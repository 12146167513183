import { Component } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ResolverService, ResolverValue } from 'ldt-resolver-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-resolver',
  templateUrl: './resolver.component.html',
  styleUrls: ['./resolver.component.scss'],
})
export class ResolverComponent {
  refreshing: boolean = false;
  resolverValues: ResolverValue[] = [];
  public colDefs: ColDef[] = [
    { field: 'input', sort: 'asc' },
    { field: 'value.msa', headerName: 'MSA (US only)' },
    { field: 'value.locality', headerName: 'Locality (city, town, etc)' },
    { field: 'value.region_abbreviation', headerName: 'Region (state, etc)' },
    { field: 'value.country_code', headerName: 'Country' },
    { field: 'metadata.version', headerName: 'Version' },
    { field: 'metadata.resolver_id', headerName: 'Resolver ID' },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    flex: 1,
  };
  constructor(
    private resolver: ResolverService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getResolverValues();
  }

  getResolverValues(): void {
    this.refreshing = true;
    this.resolver.listResolverValuesByField('location').subscribe({
      next: (data) => {
        this.resolverValues = data.resolver_values || [];
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Error fetching resolver values');
        this.refreshing = false;
      },
    });
  }
}
