import { Component } from '@angular/core';
import { Product, ProductsService } from 'ldt-billing-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class AdminBillingProductsComponent {
  refreshing: boolean = false;
  rowData: Product[] = [];

  constructor(
    private productsService: ProductsService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.getProducts();
  }

  public colDefsTypes: ColDef[] = [
    { field: 'stripeProduct.id', maxWidth: 200 },
    { field: 'stripeProduct.name', floatingFilter: true },
    {
      field: 'stripeProduct.prices',
      valueGetter: (params) =>
        params.data.stripeProduct.prices.map((p: any) => p.term + ' - ' + p.unitAmount).join(', '),
    },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  getProducts(): void {
    this.refreshing = true;
    this.productsService.getProducts().subscribe({
      next: (data) => {
        this.rowData = data.products;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('Error fetching products');
        this.refreshing = false;
      },
    });
  }
}
