<div class="tw-relative tw-w-full tw-h-screen tw-overflow-hidden">
  <!-- Full-width faded background image -->
  <img
    src="assets/img/moneyball-battle-sample.png"
    class="tw-w-full tw-h-full tw-object-cover tw-filter tw-brightness-50 tw-contrast-110 tw-opacity-50"
    alt="Background Image" />

  <!-- Pricing Card -->
  <div
    *ngIf="!($orgCapabilities | async).includes('moneyball')"
    class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-z-10 tw-min-w-[95%]">
    <app-billing-products-moneyball></app-billing-products-moneyball>
  </div>

  <!-- Pricing Card alternative -->
  <div
    *ngIf="($orgCapabilities | async).includes('moneyball')"
    class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-z-10">
    <div
      class="tw-flex tw-flex-col tw-p-6 tw-mx-auto tw-max-w-lg tw-text-center tw-text-gray-900 tw-bg-white tw-rounded-lg tw-border tw-border-gray-100 tw-border-solid tw-shadow xl:tw-p-8 tw-min-w-[250px] xs:tw-min-w-96">
      <h3 class="tw-text-2xl tw-font-semibold tw-flex tw-items-center tw-justify-center tw-mb-1">
        <mat-icon class="tw-pr-2">query_stats</mat-icon>
        Moneyball
      </h3>
      <p class="tw-font-light tw-text-gray-500 sm:tw-text-lg tw-mt-0">
        Real-Time Workforce Intelligence
      </p>

      <div
        class="tw-p-4 tw-mb-4 tw-text-blue-800 tw-border tw-border-blue-300 tw-border-solid tw-rounded-lg tw-bg-blue-50"
        role="alert">
        <!-- General message -->
        <div>
          <mat-icon class="tw-font-20">lock</mat-icon>
          <h3 class="text-lg font-medium tw-mt-0">
            You do not have a license to access this capability.
          </h3>
        </div>

        <!-- Non-admin message -->
        <div
          *ngIf="!($isOrgAdmin | async)"
          class="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-my-4">
          Please contact your administrator.
        </div>

        <!-- Admin message -->
        <div
          class="tw-mt-2 tw-mb-4 tw-text-sm"
          *ngIf="($isOrgAdmin | async)"
          class="tw-justify-center tw-items-center tw-leading-6 tw-my-4">
          Manage licenses on the
          <a
            class="tw-text-blue-600 tw-font-semibold hover:tw-text-blue-500"
            [routerLink]="['/' + orgId + '/team']">
            Team
          </a>
          page or manage your subscription on the
          <a
            class="tw-text-blue-600 tw-font-semibold hover:tw-text-blue-500"
            [routerLink]="['/' + orgId + '/settings']">
            Billing
          </a>
          page.
        </div>
      </div>
    </div>
  </div>
</div>
