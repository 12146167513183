import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-moneyball-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss'],
})
export class MoneyballSampleComponent {
  $orgCapabilities: Observable<String[]> = of([]);
  $isOrgAdmin: Observable<boolean> = of(false);
  orgId: string;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.$orgCapabilities = this.auth.$getOrgCapabilites;
    this.$isOrgAdmin = this.auth.$isOrgAdmin;
  }

  ngOnInit(): void {
    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.auth.getSelectedOrgIdValue;
      if (!orgId) {
        this.router.navigateByUrl('/main');
      }
    }
    this.orgId = orgId;
  }
}
