import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { UIFilter } from '../filters.component';
import { SearchFilter, Company } from 'ldt-dw-reader-service-api';
import { ColumnType, DWColumn } from 'src/app/data-warehouse/dw-column';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import {
  getPeopleColumnByName,
  SearchFilterOperator,
  SupportedOperators,
} from '../../people-columns';
import { BadgeValue } from 'src/app/shared/badge/badge.component';
import { CompanyInfoService } from 'src/app/shared/company-search/company-info-service.service';
import { SelectedCompany } from 'src/app/shared/company-search/company-search.component';

interface UICompany {
  id: string;
  name: string;
  group?: boolean;
}

@Component({
  selector: 'app-filter-company-search',
  templateUrl: './filter-company-search.component.html',
  styleUrls: ['./filter-company-search.component.scss'],
})
export class FilterCompanySearchComponent {
  @Input() filter: UIFilter;
  @Input() operator: 'and' | 'or';
  @Input() depth: number = 0; // depth of the filter in the filter tree
  @Output() removeFilter = new EventEmitter<UIFilter>();
  @HostBinding('class') class =
    'simple-filter-component filter-row query-item tw-relative tw-flex tw-gap-1 tw-mb-5';

  column: DWColumn;

  availableOperators: SearchFilterOperator[] = [];
  selectedOperator: SearchFilterOperator;

  selectedCompanies: UICompany[] = [];

  showMoreBadges: boolean = false;
  displayBadgeLimit: number = 5;
  displayedBadges: BadgeValue[] = [];
  animateNum: boolean = false;

  constructor(private companyInfoService: CompanyInfoService) {}
  ngOnInit() {
    try {
      this.column = getPeopleColumnByName(this.filter.filter.field!);
    } catch (e) {
      console.error('Filter field not found');
      return;
    }
    this.setAvailableOperatorsByType();
    this.loadFilterParams();
    this.updateBadgesAndValues();
    this.setFilterParams();
  }

  updateBadgesAndValues(): void {
    this.filter.filter.string_values = this.selectedCompanies.map((c) => c.id);

    const displayBadges: BadgeValue[] = this.selectedCompanies.map((c) => {
      return {
        value: c.id,
        displayValue: c.name + (c.group ? ' (conglomerate)' : ''),
      };
    });

    this.displayedBadges = this.showMoreBadges
      ? displayBadges
      : displayBadges.slice(0, this.displayBadgeLimit);
  }

  toggleShowMore() {
    this.showMoreBadges = !this.showMoreBadges;
    this.updateBadgesAndValues();
  }

  setAvailableOperatorsByType() {
    if (!this.column.type) {
      console.error('Filter field type is not set');
      return;
    }

    Object.values(SupportedOperators).forEach((operatorValue) => {
      if (operatorValue.types.includes(this.column.type!)) {
        this.availableOperators.push(operatorValue);
      }
    });
  }

  loadFilterParams() {
    if (!this.filter.filter) {
      return;
    }

    this.selectedCompanies =
      this.filter.filter.string_values?.map((id) => {
        return {
          id: id,
          name: id,
          group: id.endsWith('-group'),
        };
      }) || [];

    this.selectedCompanies
      .filter((c) => c.id !== undefined && c.id.startsWith('LDC-'))
      .forEach((c) => {
        const companyId = c.id.replace('-group', '');
        this.companyInfoService.getCompanyInfo(companyId).subscribe((company) => {
          if (company) {
            c.name = company.name;
            this.updateBadgesAndValues();
          }
        });
      });

    const operator = this.getOperatorForField(this.column.type);
    this.selectedOperator = operator ? operator : this.availableOperators[0];
  }

  getOperatorForField(fieldType: ColumnType | undefined): SearchFilterOperator | null {
    if (!fieldType) {
      return null;
    }
    const exact = this.filter.filter.match_type === SearchFilter.MatchTypeEnum.Exact;
    const must = this.filter.filter.type === SearchFilter.TypeEnum.Must;

    if (must && exact) {
      return (this.selectedOperator = SupportedOperators.equals);
    }

    return (this.selectedOperator = SupportedOperators.notEqual);
  }

  setFilterParams() {
    let matchType: SearchFilter.MatchTypeEnum = SearchFilter.MatchTypeEnum.Exact;
    let searchType: SearchFilter.TypeEnum = SearchFilter.TypeEnum.Must;

    switch (this.column.type) {
      // The only one for this component
      case ColumnType.companySearch:
        if (!this.filter.filter.string_values) {
          this.filter.filter.string_values = [];
        }

        // No need to set firstInput, handle string_values directly
        switch (this.selectedOperator.key) {
          case 'equals':
            matchType = SearchFilter.MatchTypeEnum.Exact;
            searchType = SearchFilter.TypeEnum.Must;
            break;
          case 'notEqual':
            matchType = SearchFilter.MatchTypeEnum.Exact;
            searchType = SearchFilter.TypeEnum.MustNot;
            break;
        }

        this.filter.filter.match_type = matchType;
        this.filter.filter.type = searchType;
        break;
    }
  }

  onRemove() {
    this.removeFilter.emit(this.filter);
  }

  removeBadge(value: BadgeValue): void {
    this.selectedCompanies = this.selectedCompanies.filter((c) => c.id !== value.value);
    this.updateBadgesAndValues();
  }

  // company search
  companySelectedAndAddBadge(event: SelectedCompany) {
    if (!event) return;
    if (!event.company || !event.company.name) return;

    let companyId = event.company.id!;
    if (event.type === 'group') {
      companyId += '-group';
    }
    this.selectedCompanies.push({
      id: companyId,
      name: event.company.name,
      group: event.type === 'group',
    });

    // add a badge
    this.updateBadgesAndValues();

    // Trigger btn animations if badges are truncated
    if (!this.showMoreBadges) {
      this.animateNum = true;
      setTimeout(() => {
        this.animateNum = false;
      }, 300); // match the duration of the animation in html
    }
  }

  isEvenDepth(): boolean {
    return this.depth % 2 === 0;
  }
}
