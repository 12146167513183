<div class="searchContainer">
  <mat-form-field
    class="companySearch tw-bg-white tw-rounded-[5px]"
    [floatLabel]="floatLabel"
    appearance="outline">
    <mat-label>{{labelText}}</mat-label>
    <input
      matInput
      [formControl]="companyNameControl"
      [matAutocomplete]="auto"
      #autoInput="matAutocompleteTrigger"
      #inputField />

    <mat-autocomplete
      #auto="matAutocomplete"
      [panelWidth]="500"
      class="searchAutocomplete"
      classList="searchAutocomplete"
      (optionSelected)="onCompanySelected($event)">
      <mat-option
        *ngFor="let searchItem of searchCompanies | async"
        [value]="searchItem"
        [class]="'tw-py-2 tw-border-0 tw-border-b tw-border-solid tw-border-gray-300'"
        [ngClass]="{
          'tw-h-[90px]': searchItem.company.aliases?.length > 0,
          'tw-h-[60px]': searchItem.company.aliases?.length === 0
        }">
        <div class="searchCompanyItem">
          <div class="searchCompanyNameLine">
            <div>{{ searchItem.company.name }}</div>
            <div *ngIf="searchItem.company.linkedin">
              <a
                href="https://www.linkedin.com/company/{{searchItem.company.linkedin}}"
                target="_blank"
                class="tw-flex tw-w-4 tw-h-4">
                <img src="assets/img/linkedin.png" />
              </a>
            </div>
            <mat-chip-list>
              <mat-chip
                class="chipTicker tw-bg-slate-500 tw-text-white"
                *ngIf="searchItem.company.ticker">
                <mat-icon>show_chart</mat-icon>
                {{searchItem.company.ticker}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="searchCompanyInfoLine">
            <div *ngIf="searchItem.type == 'company'" class="tw-font-bold">Company</div>
            <div
              *ngIf="searchItem.type == 'group'"
              class="tw-text-blue-600 tw-font-semibold tw-flex tw-items-center">
              <img
                src="../../assets/img/office-building.png"
                alt="office building conglomerate"
                class="tw-w-5 tw-align-middle" />
              <span>Conglomerate</span>
            </div>
            <div *ngIf="searchItem.company.industry">&#9679;</div>
            <div *ngIf="searchItem.company.industry">{{searchItem.company.industry}}</div>
            <div *ngIf="searchItem.type == 'group'">&#9679;</div>
            <div *ngIf="searchItem.type == 'group'">
              {{searchItem.company.grouped_company_count | number}} companies
            </div>
            <div *ngIf="searchItem.company.country">&#9679;</div>
            <div *ngIf="searchItem.company.country">{{searchItem.company.country}}</div>
          </div>
          <div
            *ngIf="searchItem.company.aliases.length > 0"
            class="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-text-xs tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
            <span class="tw-font-bold">Matching aliases:</span>
            <span
              *ngFor="let alias of searchItem.company.aliases"
              class="tw-bg-yellow-100 tw-inline-block tw-py-0.5 tw-px-1 tw-rounded-sm">
              {{alias}}
            </span>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="spinnerContainer">
    <mat-spinner *ngIf="searching" diameter="20"></mat-spinner>
  </div>
</div>
