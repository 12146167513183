import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountManagementComponent } from './account-management/account-management.component';
import { CleanComponent } from './admin/clean/clean.component';
import { IdentityComponent } from './admin/identity/identity.component';
import { IdentityOrgDetailsComponent } from './admin/identity/org-details/identity-org-details.component';
import { AdminLedgerComponent } from './admin/ledger/ledger.component';
import { PeopleComponent } from './admin/ledger/people/people.component';
import { InvitationsComponent } from './auth/invitations/invitations.component';
import { LogInSignUpComponent } from './auth/log-in-sign-up/log-in-sign-up.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AntiAuthGuard } from './guards/anti-auth.guard';
import { AuthGuard } from './guards/auth.guard';
import { DashboardGuard } from './guards/dashboard.guard';
import { OrgsGuard } from './guards/orgs.guard';
import { LedgerContactsComponent } from './ledger/ledger-contacts/ledger-contacts.component';
import { LedgerImportComponent } from './ledger/ledger-import/ledger-import.component';
import { LedgerResolverService } from './ledger/ledger-resolver.service';
import { LedgerComponent } from './ledger/ledger.component';
import { LedgersListComponent } from './ledger/ledgers-list/ledgers-list.component';
import { MainPageComponent } from './main-page/main-page.component';
import { Onboardingv2Component } from './onboardingv2/onboardingv2.component';
import { TeamComponent } from './team/team.component';
import { JobsComponent } from './jobs/jobs.component';
import { UploadFileComponent } from './jobs/upload/upload-file/upload-file.component';
import { MapFieldsComponent } from './jobs/upload/map-fields/map-fields.component';
import { SettingsComponent } from './settings/settings.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ActiveOrgGuard } from './guards/active-org.guard';
import { LedgerOverviewComponent } from './ledger/ledger-overview/ledger-overview.component';
import { FirehoseComponent } from './admin/firehose/firehose.component';
import { DataWarehouseComponent } from './data-warehouse/data-warehouse.component';
import { CustomSearchComponent } from './data-warehouse/custom-search/custom-search.component';
import { StatsComponent } from './data-warehouse/stats/stats.component';
import { DetailsComponent } from './data-warehouse/details/details.component';
import { MoneyballComponent } from './moneyball/moneyball.component';
import { GrowComponent } from './grow/grow.component';
import { InspectComponent } from './data-warehouse/inspect/inspect.component';
import { LedgerVersionCompareComponent } from './ledger/ledger-version-compare/ledger-version-compare.component';
import { OauthComponent } from './auth/oauth/oauth.component';
import { OsQueryComponent } from './data-warehouse/os-query/os-query.component';
import { ExtensionComponent } from './auth/extension/extension.component';
import { TechnoTargetComponent } from './techno-target/techno-target.component';
import { LedgerContactsV2Component } from './ledger/ledger-contacts-v2/ledger-contacts-v2/ledger-contacts-v2.component';
import { V2AuditComponent } from './ledger/v2-audit/v2-audit.component';
import { LedgerOverviewV2Component } from './ledger/ledger-overview-v2/ledger-overview-v2.component';
import { StagingFirehoseComponent } from './admin/staging-firehose/staging-firehose.component';
import { PeopleDetailsComponent } from './people/details/details.component';
import { DWPeopleComponent } from './people/people.component';
import { AliasesComponent } from './data-warehouse/aliases/aliases.component';
import { StreamsTrialComponent } from './admin/onboarding/streams-trial/streams-trial.component';
import { CompaniesComponent } from './data-warehouse/companies/companies.component';
import { AdminStreamsComponent } from './admin/onboarding/onboarding.component';
import { StreamsCompaniesComponent } from './admin/onboarding/streams-companies/streams-companies.component';
import { FiltersComponent } from './people/filters/filters.component';
import { PeopleFindComponent } from './people/find/find.component';
import { ConfiguredIntegrationsComponent } from './integrations/configured-integrations/configured-integrations.component';
import { AvailableIntegrationsComponent } from './integrations/available-integrations/available-integrations.component';
import { IntegrationDialogComponent } from './integrations/available-integrations/integration-dialog/integration-dialog.component';
import { DataDeliveriesComponent } from './admin/data-deliveries/data-deliveries.component';
import { DashboardComponent } from './admin/onboarding/dashboard/dashboard.component';
import { DestinationsComponent } from './admin/data-deliveries/destinations/destinations.component';
import { DestinationTypesComponent } from './admin/data-deliveries/destination-types/destination-types.component';
import { MoneyballBattleComponent } from './moneyball-battle/moneyball-battle.component';
import { ReportDefinitionsComponent } from './admin/data-deliveries/report-definitions/report-definitions.component';
import { ReportDeliveriesComponent } from './admin/data-deliveries/report-deliveries/report-deliveries.component';
import { ScheduledReportsComponent } from './admin/data-deliveries/scheduled-reports/scheduled-reports.component';
import { ReportSourcesComponent } from './admin/data-deliveries/report-sources/report-sources.component';
import { AdminMoneyballComponent } from './admin/moneyball/moneyball.component';
import { AdminBillingComponent } from './admin/billing/billing.component';
import { AdminBillingSubscriptionsComponent } from './admin/billing/subscriptions/subscriptions.component';
import { AdminBillingProductsComponent } from './admin/billing/products/products.component';

const routes: any = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    component: MainPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LogInSignUpComponent,
    data: { isLogin: true },
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'signup',
    component: LogInSignUpComponent,
    data: { isLogin: false },
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'login/extension',
    component: ExtensionComponent,
    canActivate: [],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'account',
    component: AccountManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'onboarding/:persona',
    component: Onboardingv2Component,
    canActivate: [AntiAuthGuard],
  },
  {
    path: 'onboarding',
    component: Onboardingv2Component,
    canActivate: [AntiAuthGuard],
  },
  {
    path: ':orgId/ledgers',
    component: LedgersListComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
  },
  {
    path: ':orgId/ledgers/:ledgerId',
    component: LedgerComponent,
    resolve: {
      userdata: LedgerResolverService,
    },
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
    children: [
      {
        path: 'overview',
        component: LedgerOverviewComponent,
      },
      {
        path: 'overview-v2',
        component: LedgerOverviewV2Component,
      },
      {
        path: 'details',
        component: LedgerContactsComponent,
      },
      {
        path: 'details-v2',
        component: LedgerContactsV2Component,
      },
      {
        path: 'imports',
        component: LedgerImportComponent,
      },
      {
        path: 'compare',
        component: LedgerVersionCompareComponent,
      },
      {
        path: 'audit',
        component: V2AuditComponent,
      },
    ],
  },
  {
    path: ':orgId/team',
    component: TeamComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
  },
  {
    path: ':orgId/jobs',
    component: JobsComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
  },
  {
    path: ':orgId/jobs/upload',
    component: UploadFileComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
  },
  {
    path: ':orgId/jobs/upload/:jobId/map',
    component: MapFieldsComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
  },
  {
    path: ':orgId/people',
    component: DWPeopleComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
    children: [
      { path: '', redirectTo: 'details', pathMatch: 'full' },
      {
        path: 'details',
        component: PeopleDetailsComponent,
      },
      {
        path: 'filters',
        component: FiltersComponent,
      },
      {
        path: 'find',
        component: PeopleFindComponent,
      },
    ],
  },
  {
    path: 'admin/identity',
    component: IdentityComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/ledger',
    component: AdminLedgerComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/ledger/people',
    component: PeopleComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/identity/orgs/:orgId',
    component: IdentityOrgDetailsComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/firehose',
    component: FirehoseComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/data-deliveries',
    component: DataDeliveriesComponent,
    canActivate: [DashboardGuard],
    children: [
      {
        path: 'destinations',
        component: DestinationsComponent,
      },
      {
        path: 'destination-types',
        component: DestinationTypesComponent,
      },
      {
        path: 'report-definitions',
        component: ReportDefinitionsComponent,
      },
      {
        path: 'report-deliveries',
        component: ReportDeliveriesComponent,
      },
      {
        path: 'scheduled-reports',
        component: ScheduledReportsComponent,
      },
      {
        path: 'report-sources',
        component: ReportSourcesComponent,
      },
    ],
  },
  {
    path: 'admin/billing',
    component: AdminBillingComponent,
    canActivate: [DashboardGuard],
    children: [
      {
        path: 'subscriptions',
        component: AdminBillingSubscriptionsComponent,
      },
      {
        path: 'products',
        component: AdminBillingProductsComponent,
      },
    ],
  },
  {
    path: 'admin/staging-firehose',
    component: StagingFirehoseComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/moneyball',
    component: AdminMoneyballComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: ':orgId/moneyball',
    component: MoneyballComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':orgId/moneyball/battle',
    component: MoneyballBattleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'grow',
    component: GrowComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'invitations',
    component: InvitationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invite',
    component: InvitationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/clean',
    component: CleanComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'admin/streams',
    component: AdminStreamsComponent,
    canActivate: [DashboardGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'trials', component: StreamsTrialComponent },
      { path: 'companies', component: StreamsCompaniesComponent },
    ],
  },
  {
    path: ':orgId/integrations',
    component: IntegrationsComponent,
    canActivate: [AuthGuard, OrgsGuard, ActiveOrgGuard],
    children: [
      { path: '', redirectTo: 'available-integrations', pathMatch: 'full' },
      {
        path: 'available-integrations',
        component: AvailableIntegrationsComponent,
        children: [
          {
            path: 'integration-dialog',
            component: IntegrationDialogComponent,
          },
        ],
      },
      { path: 'configured-integrations', component: ConfiguredIntegrationsComponent },
    ],
  },
  {
    path: 'datawarehouse',
    component: DataWarehouseComponent,
    canActivate: [DashboardGuard],
    children: [
      {
        path: 'search',
        component: CustomSearchComponent,
      },
      {
        path: 'stats',
        component: StatsComponent,
      },
      {
        path: 'details',
        component: DetailsComponent,
      },
      {
        path: 'inspect',
        component: InspectComponent,
      },
      {
        path: 'query',
        component: OsQueryComponent,
      },
      {
        path: 'aliases',
        component: AliasesComponent,
      },
      {
        path: 'companies',
        component: CompaniesComponent,
      },
    ],
  },
  {
    path: ':orgId/settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //     path: ':orgId/billing',
  //     component: SettingsBillingComponent,
  //     canActivate: [AuthGuard, OrgsGuard]
  // },
  {
    path: 'oauth',
    component: OauthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'techno-target',
    component: TechnoTargetComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: '**',
    component: MainPageComponent,
    data: { pageNotFound: true },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
