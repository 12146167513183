import { Component, Input } from '@angular/core';
import {
  CustomerSubscriptionsService,
  CreateCheckoutSessionRequest,
  Subscription,
} from 'ldt-billing-service-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing-products-moneyball',
  templateUrl: './moneyball.component.html',
  styleUrls: ['./moneyball.component.scss'],
})
export class MoneyballProductSubscriptionComponent {
  @Input() subscription: Subscription;
  userCount: number = 1;
  pricePerUser: number = 99;
  orgId: string;

  get totalCost(): number {
    return this.userCount * this.pricePerUser;
  }

  constructor(
    private subscriptionService: CustomerSubscriptionsService,
    private authService: AuthService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.orgId = this.authService.getSelectedOrgIdValue;
    this.userCount = this.subscription?.product?.qty || 1;
  }

  ngOnChanges(): void {
    this.userCount = this.subscription?.product?.qty || 1;
  }

  incrementUsers(): void {
    this.userCount++;
  }

  decrementUsers(): void {
    if (this.userCount > 1) {
      this.userCount--;
    }
  }

  manage(): void {
    const link = document.createElement('a');

    this.subscriptionService.createPortalSession(this.orgId).subscribe({
      next: (res) => {
        link.href = res.sessionUrl;
        link.click();
      },
      error: () => {
        this.notify.error('Oops. Problem redirecting you to Stripe. Please try again later.');
      },
    });
  }

  subscribe(): void {
    const link = document.createElement('a');

    let req: CreateCheckoutSessionRequest = {
      priceId: environment.moneyballSubscriptionPriceId,
      qty: this.userCount,
    };
    this.subscriptionService.createCheckoutSession(this.orgId, req).subscribe({
      next: (res) => {
        link.href = res.sessionUrl;
        link.click();
      },
      error: () => {
        this.notify.error('Error creating subscription');
      },
    });
  }
}
