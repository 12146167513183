<div class="content-title">Live Data Warehouse</div>
<nav mat-tab-nav-bar>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./stats">Stats</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./details">People</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./companies">Companies</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./search">Search</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./inspect">Inspect</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./resolver">Resolver</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./query">OS Query</a>
  <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./aliases">Aliases</a>
</nav>
<router-outlet></router-outlet>
