import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AdministrativeService as LedgerAdminService } from 'ldt-ledger-service-api';
import { DefaultService as CleanAdminService, JobsService } from 'ldt-clean-service-api';
import * as Highcharts from 'highcharts';
import HC_timeline from 'highcharts/modules/timeline';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  PostPeopleReplayRequest,
  SearchPersonsDataRequestData,
  SearchService,
  PeopleService,
  CompaniesService,
  Company,
} from 'ldt-dw-reader-service-api';
import { DynamoApiService, ReplayProviderDataRequest } from 'ldt-dev-deploys-api';
import { Observable, Observer, Subscription, map } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyDialogComponent } from './company-dialog/company-dialog.component';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';
import { ClipboardService } from 'ngx-clipboard';
HC_timeline(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.cross = function (
  x: number,
  y: number,
  w: number,
  h: number
) {
  return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

const STAGING_SS_WS_FEED = 'wss://fqpogpusa2.execute-api.us-east-1.amazonaws.com/Prod';
const POINTS_IN_TIMELINE = 50;

@Component({
  selector: 'app-inspect',
  templateUrl: './inspect.component.html',
  styleUrls: ['./inspect.component.scss'],
})
export class InspectComponent implements OnInit, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts; // required

  providerData: any[];
  syncEntry: any;
  cleanDetail: any;
  personList: any[] = [];
  intakeSource: any;
  cleanJobItemDetails: any;
  flywheelDetails: any;
  opensearchDetails: any;
  dataToShow: boolean = false;
  jobsToShow: boolean = false;
  isReplayContext: string;
  env: string;
  replaying: boolean = false;
  loliing: boolean = false;

  inputFormGroup: UntypedFormGroup;

  eavDateFields = [
    'created_at',
    'updated_at',
    'company_changed_at',
    'last_success_at',
    'previous_success_at',
  ];
  eavPersonFields = [
    'name',
    'is_private',
    'cell_phone_number',
    'connections',
    'country',
    'location',
  ];

  refreshing: boolean = true;
  loadingTextDefault = 'Loading history...';
  loadingText: string = this.loadingTextDefault;
  personRef: string;
  currentPerson: string | undefined;
  currentEnv: string | undefined;
  selectedJob: any;
  providers: string[] = [];
  providers_staging: string[] = [];

  private envSubscription: Subscription;

  timelineOptions = ['all', 'clean', 'dw', 'loli', 'mr', 'housefile', 'ldtinternal'];
  selectedTimelineData = new UntypedFormControl('all');

  jobsDatasource: MatTableDataSource<any>;
  OSJobsDatasource: MatTableDataSource<any>;
  jobColumns = ['company_name', 'title', 'started_at', 'ended_at', 'created'];

  pointColors: any = {
    'flywheel-persons-table': 'blue',
    mixrank: 'orange',
    clean: 'green',
    iscraper: 'purple',
    iscraperv2: 'purple',
    ldtloli: 'plum',
    'salutory-housefile': 'cyan',
    housefile: 'cyan',
    ldtinternal: 'rgb(247, 163, 92)',
  };
  pointLabels: any = {
    'flywheel-persons-table': 'FW',
    mixrank: 'MR',
    iscraper: 'IS',
    iscraperv2: 'IS2',
    clean: 'clean',
    ldtloli: 'LDTL',
    'salutory-housefile': 'housefile',
    housefile: 'housefile',
    'mixrank-api': 'MRA',
  };
  cleanStatusColors: any = {
    SAME_CO: 'lightblue',
    NEW_CO: 'lightgreen',
    NO_CURRENT_COMPANY: 'lightgray',
    MANUAL_SEARCH_REQ: 'LemonChiffon',
    UNKNOWN: 'indianred',
    FAILED: 'indianred',
  };
  cleanStatusAbbrev: any = {
    SAME_CO: 'SAME',
    NEW_CO: 'NEW',
    NO_CURRENT_COMPANY: 'NCC',
    MANUAL_SEARCH_REQ: 'MSR',
    UNKNOWN: 'UNK',
    FAILED: 'FAIL',
  };

  constructor(
    private notify: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private peopleService: LedgerAdminService,
    private cleanService: CleanAdminService,
    private searchService: SearchService,
    private dynamoApi: DynamoApiService,
    private dwPeopleService: PeopleService,
    private _formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private dwCompanyService: CompaniesService,
    private _clipboardService: ClipboardService,
    private cleanJobService: JobsService
  ) {
    this.personList = JSON.parse(localStorage.getItem('dwInspectionPersonList') || '[]');
    this.inputFormGroup = this._formBuilder.group({
      ref: ['', [Validators.required]],
      env: [''],
      staging: [false],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if ('ref' in params) {
        if ('env' in params) {
          this.inputFormGroup.get('env')?.setValue(params.env);
          if (params.env === 'staging') {
            this.inputFormGroup.get('staging')?.setValue(true);
          }
        }

        this.personRef = this.addToPersonList(params.ref);
        if (this.personRef.startsWith('LDP')) {
          this.inputValue = this.personRef;
          this.getData();
        } else {
          this.searchPerson();
        }
      } else {
        this.refreshing = false;
      }
    });
    this.selectedTimelineData.valueChanges.subscribe(() => {
      this.generateTimelineData();
    });

    this.getProviders();

    // Connect to staging search-sync websockets
    this.messages = <Subject<any>>this.connectToStagingSSFeed(STAGING_SS_WS_FEED).pipe(
      map((response: MessageEvent): any => {
        let data = JSON.parse(response.data);
        return data;
      })
    );

    this.messages.subscribe((m) => {
      m.forEach((msg: any) => {
        const pContent = JSON.parse(msg.Sns.Message);
        if (
          pContent.id?.toLowerCase() === this.syncEntry?.id?.toLowerCase() &&
          this.currentEnv === 'staging'
        ) {
          this.opensearchDetails = pContent;
          this.notify.info('Opensearch record updated');
        }
      });
    });

    this.envSubscription = this.inputFormGroup.get('staging')!.valueChanges.subscribe((val) => {
      this.currentEnv = val ? 'staging' : undefined;
    });
  }

  ngOnDestroy(): void {
    if (this.envSubscription) {
      this.envSubscription.unsubscribe();
    }
  }

  getProviders() {
    this.dynamoApi.listProviders().subscribe({
      next: (res: any) => {
        this.providers = res;
      },
      error: () => {
        this.notify.error('Error getting provider list for replays');
      },
    });
    this.dynamoApi.listProviders('staging').subscribe({
      next: (res: any) => {
        this.providers_staging = res;
        this.providers_staging.push('STAGING');
      },
      error: () => {
        this.notify.error('Error getting staging provider list for replays');
      },
    });
  }

  inputValue: string;
  inspectInputPerson() {
    this.personRef = this.addToPersonList(this.inputFormGroup.get('ref')?.value);
    this.searchPerson();
  }

  searchPerson() {
    if (!this.personRef) return;

    // Save this for later comparison
    this.inputValue = this.personRef;
    if (this.personRef.startsWith('LDLC')) {
      this.peopleService.getContactCleanHistory(this.personRef).subscribe({
        next: (res: any[]) => {
          if (res.length > 0) {
            const personId = res.find((x: any) => x.personId?.startsWith('LDP'))?.personId;
            if (personId) {
              this.personRef = personId;
              this.searchId(this.personRef);
            } else {
              this.router.navigateByUrl('/admin/ledger/people?ref=' + this.personRef);
              this.refreshing = false;
            }
          } else {
            this.notify.error('No clean history available to get an LDP');
            this.refreshing = false;
          }
        },
        error: () => {
          this.notify.error('Error getting clean history data');
          this.refreshing = false;
        },
      });
    } else if (!this.personRef.startsWith('LDP')) {
      const cleanLipid = this.personRef.split('/').slice(-1)[0];

      this.dynamoApi.searchPersonPost({ lipid: cleanLipid }).subscribe({
        next: (r: any) => {
          if (r && r.data) {
            this.personRef = r.data.id;
            this.searchId(this.personRef);
          } else {
            this.notify.error('No lipid found in intake sync');
            this.refreshing = false;
          }
        },
        error: () => {
          this.notify.error('Error calling intake sync to find the LDP');
          this.refreshing = false;
        },
      });
    } else {
      this.searchId(this.personRef);
    }
  }

  getIntakeSyncProd(lipid: string) {
    this.dynamoApi.searchPersonPost({ lipid: lipid }).subscribe({
      next: (r: any) => {
        if (r && r.data) {
          this.intakeRecordProd = r.data;
        } else {
          this.notify.error('Lipid not found in intake sync prod');
        }
      },
      error: () => {
        this.notify.error('Error calling intake sync to find the record');
      },
    });
  }

  intakeRecordProd: any;
  diffShowDifferences: boolean = true;
  getData() {
    if (!this.personRef) return;
    this.refreshing = true;

    if (this.currentEnv) {
      // ENV is set to something - get the prod version of the person too
      this.getIntakeSyncProd(this.personRef);
    }

    this.resetData();

    this.loadingText = 'Getting intake sync data for ' + this.personRef;

    // the field is "lipid", but it takes an LDP too
    // first get the authoritative intake sync record. this will have the preferred ID and lipid
    this.dynamoApi
      .searchPersonPost({ lipid: this.personRef, environment: this.currentEnv })
      .subscribe({
        next: (r: any) => {
          if (r.data?.status && r.data.status === 'No data found') {
            this.notify.error('No data found in intake sync');
            this.refreshing = false;
            return;
          }

          // Set the sync data and jobs data in the UI
          this.syncEntry = r.data;
          const now = new Date().getTime();
          this.jobsDatasource = new MatTableDataSource(
            this.syncEntry.jobs
              ?.map((j: any) => {
                j['newly_created'] = new Date(j.created_at).getTime() > now - 1000 * 60 * 60 * 24;
                j['field_change_summary'] = this.decorateJobWithChangeSummary(j);
                return j;
              })
              .sort((a: any, b: any) => b.ordering - a.ordering)
          );

          if (this.syncEntry.aliases) {
            this.syncEntry.lipidAliases = this.syncEntry.aliases
              .filter((a: any) => !a.key.startsWith('LDP') && a.key !== a.preferredLinkedin)
              .map((a: any) => a.key);
            this.syncEntry.ldpAliases = this.syncEntry.aliases
              .filter((a: any) => a.key.startsWith('LDP') && a.key !== a.preferredId)
              .map((a: any) => a.key);
          } else {
            this.syncEntry.lipidAliases = [];
            this.syncEntry.ldpAliases = [];
          }

          this.currentPerson = this.personRef;
          this.inputFormGroup.get('ref')?.setValue(this.currentPerson);

          // Populate the dropdown
          this.personList.find((p) => p.ref === this.personRef).name = this.syncEntry.linkedin;
          localStorage.setItem('dwInspectionPersonList', JSON.stringify(this.personList));

          // Notify the user if the person has been merged
          if (
            this.inputValue?.startsWith('LDP') &&
            this.inputValue?.toLocaleLowerCase() !== this.syncEntry.id.toLocaleLowerCase()
          ) {
            this.notify.info('This person has been merged. Note the new LDP');
          } else if (
            !this.inputValue?.startsWith('LDP') &&
            this.inputValue?.toLocaleLowerCase() !== this.syncEntry.linkedin.toLowerCase()
          ) {
            this.notify.info('This person has been merged. Note the new LIPID');
          }

          this.refreshing = false;

          // Get the rest of the data (provider and clean history)
          this.dynamoApi
            .searchProvidersPost({ id: this.syncEntry.id, environment: this.currentEnv })
            .subscribe({
              next: (r: any) => {
                if (r.data && r.data.length > 0) {
                  this.providerData = r.data.sort(
                    (a: any, b: any) =>
                      new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
                  );
                }
                this.generateTimelineData();
              },
              error: () => {
                this.notify.error('Error getting provider data for timeline');
              },
            });

          this.peopleService.getContactCleanHistory(this.syncEntry.id).subscribe({
            next: (r: any) => {
              this.cleanDetail = r;
              this.generateTimelineData();
            },
            error: (err: any) => {
              // If we get a 404, don't show the error message, it just means there is no clean data
              if (err.status !== 404) {
                this.notify.error('Error getting clean history for timeline');
              }
            },
          });

          // FLYWHEEL DATA
          this.peopleService.getPersonDetails(this.syncEntry.id).subscribe({
            next: (r) => {
              this.flywheelDetails = r;
            },
            error: () => {
              this.notify.error('Error getting person details from Flywheel');
            },
          });

          // Only go to OS if we're in prod
          if (!this.currentEnv) {
            // OPENSEARCH
            let searchBody: SearchPersonsDataRequestData = {
              fresh_only: false,
              filters: [
                {
                  type: 'must',
                  field: 'id',
                  string_values: [this.syncEntry.id],
                  match_type: 'exact',
                },
              ],
            };
            this.searchService.searchPersonsData(undefined, searchBody).subscribe({
              next: (r: any) => {
                if (r.count > 0) {
                  this.opensearchDetails = r.results.hits.hits[0]._source;

                  this.OSJobsDatasource = new MatTableDataSource(
                    this.opensearchDetails.jobs
                      ?.map((j: any) => {
                        j['newly_created'] =
                          new Date(j.metadata?.created_at).getTime() > now - 1000 * 60 * 60 * 24;
                        return j;
                      })
                      .sort((a: any, b: any) => b.ordering - a.ordering)
                  );
                } else {
                  this.notify.error('Could not find in Opensearch');
                }
              },
              error: () => {
                this.notify.error('Error getting Opensearch record');
              },
            });
          }
        },
        error: () => {
          this.notify.error('Error calling intake sync to find the person record');
          this.refreshing = false;
        },
      });
  }

  shouldWarn(eav: string): boolean {
    if (eav !== 'last_success_at') {
      return false;
    }

    const ninetyDaysAgo = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
    const eavValue = this.syncEntry?.values[eav]?.Value;

    if (!eavValue) return true;
    const eavDate = new Date(eavValue);

    if (eavDate < ninetyDaysAgo) {
      return true;
    }

    return false;
  }

  stagingLoading: boolean = false;
  loadToStaging(ldp: string) {
    this.stagingLoading = true;
    this.dynamoApi.deleteFromStagingPost({ ids: [ldp] }).subscribe({
      next: () => {
        this.notify.success('Successfully deleted from staging');
        this.dynamoApi.loadStagingPost({ ids: [ldp] }).subscribe({
          next: () => {
            this.notify.success('Successfully loaded to staging');
            this.stagingLoading = false;
          },
          error: () => {
            this.notify.error('Error loading to staging');
            this.stagingLoading = false;
          },
        });
      },
      error: () => {
        this.notify.error('Error deleting from staging');
        this.stagingLoading = false;
      },
    });
  }

  private decorateJobWithChangeSummary(job: any) {
    if (!job.changed_fields) return {};

    let changedFieldsSummary: any = {};

    // Sort the changed_fields array by updated_at in descending order
    job.changed_fields.sort((a: any, b: any) => {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
    });

    // Iterate over the sorted changed_fields array
    for (let change of job.changed_fields) {
      // Old versions of the API don't have a fields array, so skip those
      if (!change.fields) continue;

      // Iterate over the fields array in each change
      for (let field of change.fields) {
        // If the field is not already in changedFieldsSummary, or if the current change is more recent, update the entry
        if (
          !changedFieldsSummary[field] ||
          new Date(change.updated_at).getTime() >
            new Date(changedFieldsSummary[field].updatedAt).getTime()
        ) {
          changedFieldsSummary[field] = {
            providerId: this.pointLabels[change.provider_id] || change.provider_id || 'FW',
            updatedAt: change.updated_at,
          };
        }
      }
    }

    return changedFieldsSummary;
  }

  timelineData: string = 'all';
  generateTimelineData() {
    let normClean =
      this.cleanDetail?.map((d: any, i: any) => {
        const bonus = d.chId.split('-').length == 3;
        return {
          provider_id: 'clean',
          updated_at: new Date(d.dateRecorded),
          highlight: d.cleanStatus == 'NEW_CO' ? true : false,
          description: '',
          custom: {
            index: i,
          },
          label: this.getLabelForCleanStatus(d.cleanStatus, bonus),
        };
      }) || [];

    let normProvider =
      this.providerData?.map((d) => {
        let vals = Object.keys(this.syncEntry.values).filter(
          (v: any) =>
            this.syncEntry.values[v].ProviderID == d.provider_id &&
            this.syncEntry.values[v].UpdatedAt == d.updated_at
        );
        let desc = vals.map(
          (v) => this.syncEntry.values[v].Name + ' => ' + this.syncEntry.values[v].Value + '<br/>'
        );

        return {
          provider_id: d.provider_id,
          updated_at: new Date(d.updated_at),
          highlight: vals.length > 0 || d.event === 'DELETE' ? true : false,
          description: desc,
          deleted: d.event === 'DELETE',
          merged: d.event === 'MERGE',
          custom: {
            PK: d.PK,
            SK: d.SK,
          },
        };
      }) || [];

    let tData: any = [];
    switch (this.selectedTimelineData.value) {
      case 'all':
        tData = tData.concat(normClean);
        tData = tData.concat(normProvider);
        break;
      case 'clean':
        tData = normClean;
        break;
      case 'dw':
        tData = normProvider;
        break;
      case 'mr':
        tData = normProvider.filter(
          (p) => p.provider_id === 'mixrank' || p.provider_id === 'mixrank-api'
        );
        break;
      case 'housefile':
        tData = normProvider.filter((p) => p.provider_id === 'housefile');
        break;
      case 'ldtinternal':
        tData = normProvider.filter((p) => p.provider_id === 'ldtinternal');
        break;
      case 'loli':
        tData = normProvider.filter(
          (p) =>
            p.provider_id === 'ldtloli' ||
            p.provider_id === 'iscraper' ||
            p.provider_id === 'iscraperv2' ||
            p.provider_id === 'mixrank-api'
        );
        break;
    }
    let sortedData = tData
      .sort((a: any, b: any) => a.updated_at - b.updated_at)
      .slice(-1 * POINTS_IN_TIMELINE);

    let series = sortedData.map((d: any) => {
      return {
        x: d.updated_at.getTime(),
        name: this.pointLabels[d.provider_id] || d.provider_id,
        label:
          d.provider_id == 'clean' ? d.label : this.pointLabels[d.provider_id] || d.provider_id,
        color: this.pointColors[d.provider_id],
        marker: {
          radius: d.highlight ? 10 : 3,
          symbol: d.deleted ? 'cross' : d.merged ? 'url(assets/icons/merge.png)' : null,
          lineColor: d.deleted ? 'red' : undefined,
          width: d.merged ? 30 : undefined,
          height: d.merged ? 30 : undefined,
        },
        description: d.description,
        custom: d.custom,
      };
    });

    let options = { ...this.timelineChartOptions };
    (options.series as Highcharts.SeriesTimelineOptions[])[0].data = series;
    // options.xAxis['min'] = series[0].x - 60480000;
    (options.xAxis as Highcharts.XAxisOptions).max = new Date().getTime();
    this.timelineChartOptions = { ...options };
  }

  getLabelForCleanStatus(status: string, bonus: boolean) {
    let label =
      '<span style="background-color: ' + this.cleanStatusColors[status] + '; padding: 2px">';
    label += this.cleanStatusAbbrev[status];
    label += '</span>';
    if (bonus) label += '<br/><span style="font-size: 10px">** Bonus</span>';

    return label;
  }

  getCleanData() {
    this.peopleService.getContactCleanHistory(this.personRef).subscribe({
      next: (res) => {
        this.cleanDetail = res;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('No clean history for the provided ID');
        this.refreshing = false;
      },
    });
  }

  searchId(person: string) {
    // const inputEnv = this.inputFormGroup.get('env')?.value;
    let inputEnv = undefined;
    if (this.inputFormGroup.get('staging')!.value) {
      inputEnv = 'staging';
    }

    if (person) {
      this.personRef = person.trim();
    }
    if (this.currentPerson != this.personRef || this.currentEnv != inputEnv) {
      let url = '/datawarehouse/inspect?ref=' + this.personRef;
      if (inputEnv) {
        url += '&env=' + inputEnv;
      }
      this.router.navigateByUrl(url);
    } else {
      this.getData();
    }
  }

  isBonus(intakeSyncData: any): boolean {
    if (intakeSyncData && intakeSyncData.chId) {
      return intakeSyncData.chId.split('-').length == 3;
    }
    return false;
  }

  replayIS() {
    this.replaying = true;
    const req: PostPeopleReplayRequest = {
      ldps: [this.syncEntry.id],
      env: this.currentEnv
        ? PostPeopleReplayRequest.EnvEnum.Staging
        : PostPeopleReplayRequest.EnvEnum.Production,
      context: this.isReplayContext ? this.isReplayContext : undefined,
    };
    this.dwPeopleService.postPeopleReplay(req).subscribe({
      next: () => {
        this.notify.success('Replay request submitted for: ' + this.syncEntry.id);
        this.replaying = false;
      },
      error: () => {
        this.notify.error('Error submitting replay request for: ' + this.syncEntry.id);
        this.replaying = false;
      },
    });
  }

  replayPI(provider: string) {
    this.replaying = true;

    const req: ReplayProviderDataRequest = {
      resource: ReplayProviderDataRequest.ResourceEnum.People,
      provider_id: provider,
      lipids: [provider === 'flywheel-persons-table' ? this.syncEntry.id : this.syncEntry.linkedin],
      environment: this.currentEnv,
    };
    this.dynamoApi.replayProviderData(req).subscribe({
      next: () => {
        this.notify.success('Replay request submitted for: ' + this.syncEntry.linkedin);
        this.replaying = false;
      },
      error: () => {
        this.notify.error('Error submitting replay request for: ' + this.syncEntry.linkedin);
        this.replaying = false;
      },
    });
  }

  triggerLoli(lipid: string) {
    this.loliing = true;

    const file = new File(['linkedin\n' + lipid], 'manual_loli.csv', { type: 'text/csv' });

    this.cleanJobService.orgsOrgIdLoliJobPost('o_8eba54ab', file, 'body').subscribe({
      next: () => {
        this.notify.success('Triggered loli job for ' + lipid);
        this.loliing = false;
      },
      error: () => {
        this.notify.error('Error triggering loli job for ' + lipid);
        this.loliing = false;
      },
    });
  }

  showCompanyInfo(lookup: string) {
    this.dwCompanyService.getCompanyByLookup(lookup).subscribe({
      next: (r: Company) => {
        if (r) {
          this.dialog.open(CompanyDialogComponent, {
            data: r,
          });
        }
      },
      error: () => {
        this.notify.error('Error getting company details');
      },
    });
  }

  // Save searched persons in session storage
  addToPersonList(refs: string) {
    let persons = refs.split(/[\s,]+/);
    persons.forEach((p, i) => {
      const parts = p.trim().split('/');
      p = parts[parts.length - 1];
      persons[i] = p;
      let exists = this.personList.find((op) => {
        return op.ref === p || op.name === p;
      });
      if (!exists) {
        this.personList.push({ ref: p, name: p });
      }
    });
    localStorage.setItem('dwInspectionPersonList', JSON.stringify(this.personList));
    return persons[0];
  }
  removeFromPersonList(people: string) {
    const refs = people.split(',');
    refs.forEach((ref) => {
      this.personList = this.personList.filter((p) => p.ref !== ref);
    });
    localStorage.setItem('dwInspectionPersonList', JSON.stringify(this.personList));
  }
  clearPersonList() {
    this.personList = [];
    localStorage.setItem('dwInspectionPersonList', JSON.stringify(this.personList));
  }

  gotoFlywheelDetails() {
    this.router.navigate(['/admin/ledger/people'], { queryParams: { ref: this.personRef } });
  }

  valueAsString(obj: any) {
    if (!obj) return '';

    var str = '';
    str += 'Created: ' + obj.CreatedAt + '\n';
    str += 'Updated: ' + obj.UpdatedAt + '\n';
    str += 'Provider: ' + obj.ProviderID;

    return str;
  }

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;

  private connectToStagingSSFeed(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.createStagingSSConnection(url);
    }
    return this.subject;
  }

  private createStagingSSConnection(url: string): AnonymousSubject<MessageEvent> {
    let ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });

    return new AnonymousSubject<MessageEvent>(undefined, observable);
  }

  resetData() {
    this.providerData = [];
    this.cleanDetail = null;
    this.cleanJobItemDetails = null;
    this.selectedJob = null;

    this.syncEntry = undefined;
    this.intakeSource = '';
    this.intakeSourceJobs = undefined;
    this.currentPerson = undefined;
    this.intakeRecordProd = undefined;
    this.opensearchDetails = undefined;
    this.flywheelDetails = undefined;
    if (this.OSJobsDatasource) this.OSJobsDatasource.data = [];
  }

  intakeSourceDate: any;
  intakeSourceJobs: any;
  tryItQuery: string | undefined;
  signalClick(e: any) {
    this.cleanJobItemDetails = null;
    this.intakeSourceDate = null;
    this.intakeSource = null;
    this.intakeSourceJobs = undefined;

    // If 'index' it's a clean thing
    if ('index' in e.point.custom) {
      this.intakeSource = this.cleanDetail[e.point.custom.index];
      this.intakeSourceJobs = this.cleanResultToJobs(this.intakeSource);

      this.cleanService.getCleanJobItem(this.cleanDetail[e.point.custom.index].chId).subscribe({
        next: (r) => {
          this.cleanJobItemDetails = r;
          this.intakeSourceDate = this.intakeSource.dateRecorded;
          this.tryItQuery = this.generateQueryString(this.cleanJobItemDetails);
        },
        error: () => {
          this.notify.error('Error getting clean job item details');
        },
      });
    } else {
      const intakePoint = this.providerData.find(
        (d) => d.PK == e.point.custom.PK && d.SK == e.point.custom.SK
      );
      this.intakeSourceJobs = this.providerJobsToJobs(intakePoint);
      this.intakeSourceDate = intakePoint.created_at;
      this.intakeSource = intakePoint.json;
    }
  }

  providerJobsToJobs(providerData: any) {
    switch (providerData.provider_id) {
      case 'iscraper':
        return this.iscraperJobsToJobs(providerData);
      case 'iscraperv2':
        return this.iscraper2JobsToJobs(providerData);
      case 'flywheel-persons-table':
        return this.flywheelJobToJobs(providerData);
      case 'mixrank':
        return this.mixrankJobToJobs(providerData);
      case 'mixrank-api':
        return this.mixrankApiJobToJobs(providerData);
      case 'ldtloli':
        return this.ldtloliJobToJobs(providerData);
    }
  }

  cleanResultToJobs(providerData: any) {
    return [
      {
        company_name: providerData.orgName,
        title: providerData.personTitle,
      },
    ];
  }

  generateQueryString(cjiDetail: any): string {
    if (cjiDetail.inputs.query) {
      return cjiDetail.inputs.query;
    }

    let query =
      cjiDetail.inputs.name?.toLowerCase() + '+' + cjiDetail.inputs.company?.toLowerCase();
    query += '+' + (cjiDetail.inputs.linkedin || 'linkedin').toLowerCase();
    return query;
  }

  ldtloliJobToJobs(providerData: any) {
    let jobs: any = [];
    providerData.json?.info?.experience?.forEach((j: any) => {
      var startedDate, startedYearOnly, endedDate, endedYearOnly;
      if (j.date) {
        [startedDate, startedYearOnly] = this.iScraperDateToDate({
          month: j.date.start.month,
          year: j.date.start.year,
        });
        [endedDate, endedYearOnly] = this.iScraperDateToDate({
          month: j.date.end.month,
          year: j.date.end.year,
        });
      }

      let job = {
        company_name: j.org,
        title: j.title,
        started_at: startedDate,
        ended_at: endedDate,
        metadata: {
          started_at_year_only: startedYearOnly,
          ended_at_year_only: endedYearOnly,
        },
      };
      jobs.push(job);
    });

    return jobs;
  }

  mixrankApiJobToJobs(providerData: any) {
    let jobs: any = [];
    providerData.json.experience.forEach((j: any) => {
      let company_linkedin = '';
      if (j.url) {
        company_linkedin = j.url.split('/').slice(-1)[0];
      }
      let job = {
        company_name: j.company,
        title: j.title,
        started_at: j.start_date,
        ended_at: j.end_date,
        company_linkedin: company_linkedin,
      };
      jobs.push(job);
    });

    return jobs;
  }

  mixrankJobToJobs(providerData: any) {
    let jobs: any = [];
    providerData.json.experience.forEach((j: any) => {
      const [startedDate, startedYearOnly] = this.iScraperDateToDate({
        month: j.start_date_month,
        year: j.start_date_year,
      });
      const [endedDate, endedYearOnly] = this.iScraperDateToDate({
        month: j.end_date_month,
        year: j.end_date_year,
      });

      let job = {
        company_name: j.company_name,
        title: j.title,
        started_at: startedDate,
        ended_at: endedDate,
        metadata: {
          started_at_year_only: startedYearOnly,
          ended_at_year_only: endedYearOnly,
        },
      };
      jobs.push(job);
    });

    return jobs;
  }

  flywheelJobToJobs(providerData: any) {
    let job = {
      company_name: providerData.json.org_name,
      title: providerData.json.person_title,
      company_linkedin: '',
      email: providerData.json.person_email,
      email_status: providerData.json.person_email_status,
    };

    return [job];
  }

  copyDataToShow() {
    if (this.dataToShow) {
      this._clipboardService.copyFromContent(JSON.stringify(this.opensearchDetails, null, 2));
    } else {
      this._clipboardService.copyFromContent(JSON.stringify(this.syncEntry, null, 2));
    }
    this.notify.success('Copied to clipboard');
  }

  copyIntakeSource() {
    this._clipboardService.copyFromContent(JSON.stringify(this.intakeSource, null, 2));
    this.notify.success('Copied to clipboard');
  }
  iscraperJobsToJobs(providerData: any) {
    let jobs: any = [];
    providerData.json.position_groups.forEach((pg: any) => {
      let company_linkedin = '';
      if (pg.company.url) {
        company_linkedin = pg.company.url.split('/').slice(-2)[0];
      }
      pg.profile_positions.forEach((pp: any) => {
        const [startedDate, startedYearOnly] = this.iScraperDateToDate(pp.date.start);
        const [endedDate, endedYearOnly] = this.iScraperDateToDate(pp.date.end);

        let job = {
          company_name: pp.company,
          company_linkedin: company_linkedin,
          title: pp.title,
          started_at: startedDate,
          ended_at: endedDate,
          metadata: {
            started_at_year_only: startedYearOnly,
            ended_at_year_only: endedYearOnly,
          },
        };
        jobs.push(job);
      });
    });

    return jobs;
  }
  iscraper2JobsToJobs(providerData: any) {
    let jobs: any = [];
    providerData.json.work_experience.forEach((we: any) => {
      let company_linkedin = '';
      if (we.company.url) {
        company_linkedin = we.company?.url?.split('/').slice(-2)[0];
      }
      const [startedDate, startedYearOnly] = this.iScraperDateToDate(we.date?.start);
      const [endedDate, endedYearOnly] = this.iScraperDateToDate(we.date?.end);

      let job = {
        company_name: we.company.name,
        company_linkedin: company_linkedin,
        title: we.title,
        started_at: startedDate,
        ended_at: endedDate,
        metadata: {
          started_at_year_only: startedYearOnly,
          ended_at_year_only: endedYearOnly,
        },
      };

      if (job.company_name === null && job.title === null) {
        return;
      }

      jobs.push(job);
    });

    return jobs;
  }

  iScraperDateToDate(date: any) {
    if (!date) return [null, false];
    let { year, month } = date;

    if (year === null) {
      return [null, false];
    }

    let yearOnly = false;
    const day = 1;
    if (month === null) {
      yearOnly = true;
      month = 1;
    }

    const jdate = new Date(year, month - 1, day);
    return [jdate, yearOnly];
  }

  sortObject(obj: any) {
    if (typeof obj !== 'object' || obj === null) return obj;

    const sortedObject = Object.keys(obj)
      .sort()
      .reduce((result: any, key) => {
        result[key] = typeof obj[key] === 'object' ? this.sortObject(obj[key]) : obj[key];
        return result;
      }, {});

    return sortedObject;
  }

  timelineChartOptions: Highcharts.Options = {
    credits: { enabled: false },
    chart: {
      zooming: {
        type: 'x',
      },
      type: 'timeline',
    },
    xAxis: {
      type: 'datetime',
      visible: false,
    },
    yAxis: {
      gridLineWidth: 1,
      title: undefined,
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '',
    },
    tooltip: {
      style: {
        width: 300,
      },
      outside: true,
    },
    series: [
      {
        data: [],
        type: 'timeline',
        allowPointSelect: true,
        events: {
          click: (e: any) => {
            this.signalClick(e);
          },
        },
        dataLabels: {
          allowOverlap: false,
          format:
            '<span style="color:{point.color}">● </span><span style="font-weight: bold;" > ' +
            '{point.x:%b %e}</span><br/>{point.label}',
          useHTML: true,
        },
        marker: {
          symbol: 'circle',
        },
      },
    ],
  };
}
