import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CreateCheckoutSessionRequest,
  CustomerSubscriptionsService,
} from 'ldt-billing-service-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss'],
})
export class SubscriptionDialogComponent {
  userCount: number = 1;
  pricePerUser: number = 499;
  orgId: string;

  get totalCost(): number {
    return this.userCount * this.pricePerUser;
  }

  constructor(
    private dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    private subscriptionService: CustomerSubscriptionsService,
    private authService: AuthService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.orgId = this.authService.getSelectedOrgIdValue;
  }

  incrementUsers(): void {
    this.userCount++;
  }

  decrementUsers(): void {
    if (this.userCount > 1) {
      this.userCount--;
    }
  }

  subscribe(): void {
    console.log(`Subscribed with ${this.userCount} users for $${this.totalCost}`);
    this.dialogRef.close();
    const link = document.createElement('a');

    let req: CreateCheckoutSessionRequest = {
      priceId: environment.moneyballSubscriptionPriceId,
    };
    this.subscriptionService.createCheckoutSession(this.orgId, req).subscribe({
      next: (res) => {
        link.href = res.sessionUrl;
        link.click();
        this.notify.success('Subscription created');
      },
      error: () => {
        this.notify.error('Error creating subscription');
      },
    });
  }
}
