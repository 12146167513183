<!-- Pricing Card alternative -->
<div
  class="tw-flex tw-flex-col tw-p-6 tw-mx-auto tw-max-w-lg tw-text-center tw-text-gray-900 tw-bg-white tw-rounded-lg tw-border tw-border-gray-100 tw-border-solid tw-shadow xl:tw-p-8 ">
  <h3 class="tw-text-2xl tw-font-semibold tw-mb-1">Moneyball Subscription</h3>
  <p class="tw-font-light tw-text-gray-500 sm:tw-text-lg tw-mt-0">
    Real-Time Workforce Intelligence
  </p>

  <!-- Price -->
  <div class="tw-flex tw-justify-center tw-items-baseline tw-my-6">
    <span class="tw-text-gray-800 tw-mr-2 tw-text-5xl tw-font-extrabold">${{pricePerUser}}</span>
    <span class="tw-text-gray-500">/month per user</span>
  </div>

  <!-- List -->
  <ul role="list" class="tw-mb-4 tw-space-y-2 tw-text-left">
    <li class="tw-flex tw-items-center tw-space-x-3">
      <mat-icon class="tw-text-green-500">check</mat-icon>
      <span>Analyze and benchmark any companies</span>
    </li>
    <li class="tw-flex tw-items-center tw-space-x-3">
      <mat-icon class="tw-text-green-500">check</mat-icon>
      <span>Analyze arrivals, departures, churn, tenure, and more</span>
    </li>
    <li class="tw-flex tw-items-center tw-space-x-3">
      <mat-icon class="tw-text-green-500">check</mat-icon>
      <span>Chart download capability</span>
    </li>
  </ul>

  <!-- User Count -->
  <div class="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-my-4">
    <label for="userCount" class="tw-font-medium">Number of users:</label>
    <div class="tw-flex tw-items-center">
      <button
        *ngIf="!subscription"
        class="tw-text-lg tw-font-bold tw-bg-gray-300 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center hover:tw-bg-gray-400 tw-transition-all"
        (click)="decrementUsers()">
        -
      </button>
      <span class="tw-text-xl tw-font-semibold tw-mx-4" id="userCount">{{ userCount }}</span>
      <button
        *ngIf="!subscription"
        class="tw-text-lg tw-font-bold tw-bg-gray-300 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center hover:tw-bg-gray-400 tw-transition-all"
        (click)="incrementUsers()">
        +
      </button>
    </div>
  </div>

  <!-- Total Price -->
  <div *ngIf="!subscription" class="tw-flex tw-justify-center tw-gap-2 tw-items-center tw-my-6">
    <span class="tw-text-gray-600 tw-font-medium">Total Price:</span>
    <div class="tw-text-3xl tw-font-bold tw-text-primary_purple-600">
      {{ totalCost | currency:'USD':'symbol':'1.0-0' }}
      <span class="tw-text-gray-500 tw-font-normal tw-text-base">/billed monthly</span>
    </div>
    <!-- <span class="tw-text-gray-500 tw-text-sm">/billed monthly</span> -->
  </div>

  <a
    *ngIf="!subscription"
    (click)="subscribe()"
    class="tw-text-white tw-shrink-0 tw-relative tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden tw-text-sm tw-rounded-md tw-bg-gradient-to-br tw-from-purple-600 tw-to-blue-600 hover:tw-from-blue-600 hover:tw-to-purple-600 focus:tw-outline-none focus:tw-ring-blue-500 tw-transition-all tw-p-0.5">
    <span class="tw-relative tw-px-5 tw-py-2 tw-rounded-md tw-font-semibold">Get started</span>
  </a>

  <a
    *ngIf="subscription"
    (click)="manage()"
    class="tw-text-white tw-bg-primary_purple-600 hover:tw-bg-primary-700 focus:tw-ring-4 focus:tw-ring-primary-200 tw-font-medium tw-rounded-lg tw-text-sm tw-text-center tw-px-5 tw-py-3 tw-mt-4">
    Manage/Cancel
  </a>
</div>
