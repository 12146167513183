import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  CompaniesService,
  Company,
  CreateArrivalDepartureReport200ResponseInner,
  CreateArrivalDepartureReportRequest,
  CreateEmployeeDemoReport200ResponseInner,
  CreateEmployeeDemoReportRequest,
  CreateEmployeeTenureReport200ResponseInner,
  CreateEmployeeTenureReportRequest,
  FindCompanyRequest,
  PostReportsTenureByCohort200ResponseInner,
  PostReportsTenureByCohortRequest,
} from 'ldt-moneyball-api';
import { Observable, forkJoin, map, of } from 'rxjs';
import * as utc from 'dayjs/plugin/utc';
import { JobFunctions, JobLevels } from '../data-warehouse/dw-column';

dayjs.extend(utc);

export type SankeyNode = [string, string, number];

@Injectable({
  providedIn: 'root',
})
export class MoneyballService {
  private apiCache = new Map<string, any>(); // Map to store API responses
  private maxCacheSize = 100; // Maximum number of cached items allowed

  constructor(private companiesService: CompaniesService) {}

  // Add the API response to the cache with LRU eviction logic
  private addToCache(key: string, response: any): void {
    if (this.apiCache.size >= this.maxCacheSize) {
      const firstKey = this.apiCache.keys().next().value;
      this.apiCache.delete(firstKey);
    }
    this.apiCache.set(key, response);
  }

  getHistoricalEmployeeCounts(
    orgId: string,
    companies: string[],
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date,
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined,
    diffs: boolean = false
  ): Observable<number[][]> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: companies,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      include_grouped_companies: includeGroupedCompanies,
      group_by:
        depts || levels
          ? [
              CreateEmployeeDemoReportRequest.GroupByEnum.Department,
              CreateEmployeeDemoReportRequest.GroupByEnum.Level,
            ]
          : undefined,
    };

    const reqKey = 'createEmployeeDemoReport' + JSON.stringify(req);
    if (this.apiCache.has(reqKey)) {
      return of(
        this.processHistoricalEmployeeCountsResponse(
          this.apiCache.get(reqKey),
          depts,
          levels,
          diffs
        )
      );
    }

    return this.companiesService.createEmployeeDemoReport(orgId, req).pipe(
      map((res) => {
        this.addToCache(reqKey, res);
        return this.processHistoricalEmployeeCountsResponse(res, depts, levels, diffs);
      })
    );
  }

  private processHistoricalEmployeeCountsResponse(
    res: CreateEmployeeDemoReport200ResponseInner[],
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined,
    diffs: boolean = false
  ): number[][] {
    let filteredData = res;
    if (depts && depts.length > 0) {
      filteredData = filteredData.filter((item) =>
        depts.includes(item.group_values[0].value as JobFunctions)
      );
    }

    if (levels && levels.length > 0) {
      filteredData = filteredData.filter((item) =>
        levels.includes(item.group_values[1].value as JobLevels)
      );
    }

    if (filteredData.length === 0) {
      return [];
    }

    if (!diffs) {
      const byDate = filteredData.reduce(
        (acc, item) => {
          const date = new Date(item.date!).valueOf();
          acc[date] = (acc[date] || 0) + item.count_employees;
          return acc;
        },
        {} as { [key: number]: number }
      );

      return Object.entries(byDate)
        .map(([key, value]) => [Number(key), value] as [number, number])
        .sort((a, b) => a[0] - b[0]);
    }

    const byDateRate = filteredData.reduce(
      (acc, item) => {
        const date = new Date(item.date!).valueOf();
        acc[date] = (acc[date] || 0) + item.count_employees;
        return acc;
      },
      {} as { [key: number]: number }
    );

    const byDataArray = Object.entries(byDateRate).map(
      ([key, value]) => [Number(key), value] as [number, number]
    );

    const sortedData = byDataArray.sort((a, b) => a[0] - b[0]);
    const startValue = sortedData[0][1] || 1;

    return sortedData.map((item) => {
      return [item[0], (item[1] - startValue) / startValue];
    });
  }

  // For the provided inputs, provides chart-ready data in the form [[date, churnRate], [date, churnRate], ...]. Churn rate
  // is provided as a whole percentage (i.e. 14 , not 0.14)
  getHistoricalTurnoverRates(
    orgId: string,
    companyId: string,
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date,
    turnoverType: 'arrivals' | 'departures' | 'turnover' = 'departures',
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ): Observable<number[][]> {
    return forkJoin([
      this.getFilteredEmployeeMonthlyCounts(
        orgId,
        [companyId],
        includeGroupedCompanies,
        startDate,
        endDate,
        depts,
        levels
      ),
      this.getFilteredEmployeeArrDepCounts(
        orgId,
        [companyId],
        includeGroupedCompanies,
        startDate,
        endDate,
        depts,
        levels
      ),
    ]).pipe(
      map((res) => {
        const monthlyCounts = res[0];
        const arrDepCounts = res[1];

        // Create the new array of [date, churnRate] pairs
        const churnRateArray = Object.keys(monthlyCounts).map((date: any) => {
          let measurement = arrDepCounts[date]?.departures || 0;
          if (turnoverType === 'arrivals') {
            measurement = arrDepCounts[date]?.arrivals || 0;
          } else if (turnoverType === 'turnover') {
            measurement = arrDepCounts[date]?.arrivals + arrDepCounts[date]?.departures || 0;
          }

          const total = monthlyCounts[date];
          const churnRate = measurement / total;

          return [Number(date), 100 * churnRate];
        });

        return churnRateArray;
      })
    );
  }

  // Get employee monthly counts filtered by depts and level. Makes a call to MB to get all
  // the data grouped by those 2 dimensions, then filters locally.
  // Returns: array of {date(number): count(number)} objects
  private getFilteredEmployeeMonthlyCounts(
    orgId: string,
    companies: string[],
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date,
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ): Observable<{ [key: number]: number }> {
    let req: CreateEmployeeDemoReportRequest = {
      companies: companies,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      include_grouped_companies: includeGroupedCompanies,
      group_by: [
        CreateEmployeeDemoReportRequest.GroupByEnum.Department,
        CreateEmployeeDemoReportRequest.GroupByEnum.Level,
      ],
    };

    const reqKey = 'createEmployeeDemoReport' + JSON.stringify(req);
    if (this.apiCache.has(reqKey)) {
      return of(
        this.processFilteredEmployeeMonthlyCounts(this.apiCache.get(reqKey), depts, levels)
      );
    }

    return this.companiesService.createEmployeeDemoReport(orgId, req).pipe(
      map((res) => {
        this.addToCache(reqKey, res);
        return this.processFilteredEmployeeMonthlyCounts(res, depts, levels);
      })
    );
  }

  private processFilteredEmployeeMonthlyCounts(
    res: CreateEmployeeDemoReport200ResponseInner[],
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ) {
    let filteredData = res;
    if (depts && depts.length > 0) {
      filteredData = filteredData.filter((item) =>
        depts.includes(item.group_values[0].value as JobFunctions)
      );
    }

    if (levels && levels.length > 0) {
      filteredData = filteredData.filter((item) =>
        levels.includes(item.group_values[1].value as JobLevels)
      );
    }

    const byDate = filteredData.reduce(
      (acc, item) => {
        const date = new Date(item.date!).valueOf();
        acc[date] = (acc[date] || 0) + item.count_employees;
        return acc;
      },
      {} as { [key: number]: number }
    );

    return byDate;
  }

  // Get employee monthly arrivals/departures filtered by depts and level. Makes a call to MB to get all
  // the data grouped by those 2 dimensions, then filters locally.
  // Returns: array of {date(number): {arrivals, departures}} objects
  private getFilteredEmployeeArrDepCounts(
    orgId: string,
    companies: string[],
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date,
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ): Observable<{ [key: number]: { arrivals: number; departures: number } }> {
    let req: CreateArrivalDepartureReportRequest = {
      companies: companies,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      include_grouped_companies: includeGroupedCompanies,
    };

    const reqKey = 'createArrivalDepartureReport' + JSON.stringify(req);
    if (this.apiCache.has(reqKey)) {
      return of(this.processFilteredEmployeeArrDepCounts(this.apiCache.get(reqKey), depts, levels));
    }

    return this.companiesService.createArrivalDepartureReport(orgId, req).pipe(
      map((res) => {
        this.addToCache(reqKey, res);
        return this.processFilteredEmployeeArrDepCounts(res, depts, levels);
      })
    );
  }

  private processFilteredEmployeeArrDepCounts(
    res: CreateArrivalDepartureReport200ResponseInner[],
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ) {
    let filteredData = res;
    if (depts && depts.length > 0) {
      filteredData = filteredData.filter((item) => depts.includes(item.department as JobFunctions));
    }

    if (levels && levels.length > 0) {
      filteredData = filteredData.filter((item) => levels.includes(item.level as JobLevels));
    }

    const byDate = filteredData.reduce(
      (acc, item) => {
        const date = new Date(item.date!).valueOf();
        acc[date] = acc[date] || { arrivals: 0, departures: 0 };
        acc[date].arrivals += item.count_arrivals;
        acc[date].departures += item.count_departures;
        return acc;
      },
      {} as { [key: number]: { arrivals: number; departures: number } }
    );

    return byDate;
  }

  getHistoricalTenureData(
    orgId: string,
    companyId: string,
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date,
    status: CreateEmployeeTenureReportRequest.StatusEnum = CreateEmployeeTenureReportRequest
      .StatusEnum.Departed,
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ): Observable<{ x: number; y: number; sampleSize: number }[]> {
    let req: CreateEmployeeTenureReportRequest = {
      companies: [companyId],
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      status: status,
      group_by:
        depts || levels
          ? [
              CreateEmployeeTenureReportRequest.GroupByEnum.Department,
              CreateEmployeeTenureReportRequest.GroupByEnum.Level,
            ]
          : undefined,
      include_grouped_companies: includeGroupedCompanies,
    };

    const reqKey = 'createEmployeeTenureReport' + JSON.stringify(req);
    if (this.apiCache.has(reqKey)) {
      return of(this.processHistoricalTenureData(this.apiCache.get(reqKey), depts, levels));
    }

    return this.companiesService.createEmployeeTenureReport(orgId, req).pipe(
      map((res) => {
        this.addToCache(reqKey, res);
        return this.processHistoricalTenureData(res, depts, levels);
      })
    );
  }

  private processHistoricalTenureData(
    res: CreateEmployeeTenureReport200ResponseInner[],
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ) {
    let filteredData = res;
    if (depts && depts.length > 0) {
      filteredData = filteredData.filter((item) =>
        depts.includes(item.group_values[0].value as JobFunctions)
      );
    }

    if (levels && levels.length > 0) {
      filteredData = filteredData.filter((item) =>
        levels.includes(item.group_values[1].value as JobLevels)
      );
    }

    const byDate = filteredData.reduce(
      (acc, item) => {
        const date = new Date(item.date!).valueOf();
        if (!acc[date]) {
          acc[date] = { y: item.avg_tenure, sampleSize: item.count_employees };
        } else {
          acc[date] = {
            y:
              (acc[date].y * acc[date].sampleSize + item.avg_tenure * item.count_employees) /
              (acc[date].sampleSize + item.count_employees),
            sampleSize: acc[date].sampleSize + item.count_employees,
          };
        }
        return acc;
      },
      {} as { [key: number]: { y: number; sampleSize: number } }
    );

    return Object.entries(byDate).map(([key, value]) => {
      return { x: Number(key), y: value.y, sampleSize: value.sampleSize };
    });
  }

  // Get tenure by cohort data
  getBucketedTenureData(
    orgId: string,
    companyId: string,
    includeGroupedCompanies: boolean,
    cohortSize: number,
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ): Observable<number[]> {
    return this.getFilteredTenureByCohortData(
      orgId,
      [companyId],
      includeGroupedCompanies,
      cohortSize,
      depts,
      levels
    );
  }

  private getFilteredTenureByCohortData(
    orgId: string,
    companies: string[],
    includeGroupedCompanies: boolean,
    cohortSize: number,
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined
  ): Observable<number[]> {
    let req: PostReportsTenureByCohortRequest = {
      companies: companies,
      slice_size: cohortSize,
      group_by: [
        PostReportsTenureByCohortRequest.GroupByEnum.Department,
        PostReportsTenureByCohortRequest.GroupByEnum.Level,
      ],
      include_grouped_companies: includeGroupedCompanies,
    };

    const sliceCount = 5;

    const reqKey = 'postReportsTenureByCohort' + JSON.stringify(req);
    if (this.apiCache.has(reqKey)) {
      return of(
        this.processFilteredTenureByCohortData(this.apiCache.get(reqKey), depts, levels, sliceCount)
      );
    }

    return this.companiesService.postReportsTenureByCohort(orgId, req).pipe(
      map((res) => {
        this.addToCache(reqKey, res);
        return this.processFilteredTenureByCohortData(res, depts, levels, sliceCount);
      })
    );
  }

  private processFilteredTenureByCohortData(
    res: PostReportsTenureByCohort200ResponseInner[],
    depts: JobFunctions[] | undefined = undefined,
    levels: JobLevels[] | undefined = undefined,
    sliceCount: number
  ) {
    let filteredData = res;
    if (depts && depts.length) {
      filteredData = filteredData.filter((item) =>
        depts.includes(item.group_values![0].value as JobFunctions)
      );
    }

    if (levels && levels.length) {
      filteredData = filteredData.filter((item) =>
        levels.includes(item.group_values![1].value as JobLevels)
      );
    }

    const totalEmployees = filteredData.reduce((acc, item) => acc + (item.count_employees || 0), 0);
    if (totalEmployees === 0) {
      return new Array(sliceCount).fill(0);
    }

    const byKey = filteredData.reduce((acc, item) => {
      const key = item.key!; // TODO fix spec
      acc[key] = acc[key] + item.count_employees!; // TODO fix spec
      return acc;
    }, new Array(sliceCount).fill(0));

    return byKey.map((count) => (100 * count) / totalEmployees);
  }

  getSankeyData(
    orgId: string,
    company: Company,
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date,
    maxItems: number = 10
  ): Observable<SankeyNode[]> {
    return forkJoin([
      this.getEmployeeNextCompanyData(
        orgId,
        company.id,
        includeGroupedCompanies,
        startDate,
        endDate
      ),
      this.getEmployeePreviousCompanyData(
        orgId,
        company.id,
        includeGroupedCompanies,
        startDate,
        endDate
      ),
    ]).pipe(
      map((res) => {
        return this.generateSankeySeries(company.name, res[0], res[1], maxItems);
      })
    );
  }

  private generateSankeySeries(
    companyName: string,
    nextData: CreateEmployeeDemoReport200ResponseInner[],
    prevData: CreateEmployeeDemoReport200ResponseInner[],
    maxItems: number
  ): SankeyNode[] {
    let data: SankeyNode[] = [];

    data = data.concat(
      prevData
        .filter(
          (c: CreateEmployeeDemoReport200ResponseInner) =>
            c.group_values[0].value !== 'None' && c.group_values[0].value !== companyName
        )
        .slice(0, maxItems)
        .map((c: CreateEmployeeDemoReport200ResponseInner) => [
          c.group_values[0].value + ' ',
          companyName,
          c.count_employees,
        ])
    );

    data = data.concat(
      nextData
        .filter(
          (c: CreateEmployeeDemoReport200ResponseInner) =>
            c.group_values[0].value !== 'None' && c.group_values[0].value !== companyName
        )
        .slice(0, maxItems)
        .map((c: CreateEmployeeDemoReport200ResponseInner) => [
          companyName,
          c.group_values[0].value,
          c.count_employees,
        ])
    );

    return data;
  }

  private getEmployeeNextCompanyData(
    orgId: string,
    companyId: string,
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    let groupings = [CreateEmployeeDemoReportRequest.GroupByEnum.NextCompany];

    let req: CreateEmployeeDemoReportRequest = {
      companies: [companyId],
      group_by: groupings,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      aggregate_type: CreateEmployeeDemoReportRequest.AggregateTypeEnum.Range,
      include_grouped_companies: includeGroupedCompanies,
    };
    return this.companiesService.createEmployeeDemoReport(orgId, req).pipe(
      map((res) => {
        return res;
      })
    );
  }

  private getEmployeePreviousCompanyData(
    orgId: string,
    companyId: string,
    includeGroupedCompanies: boolean,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    let groupings = [CreateEmployeeDemoReportRequest.GroupByEnum.PreviousCompany];

    let req: CreateEmployeeDemoReportRequest = {
      companies: [companyId],
      group_by: groupings,
      date_from: dayjs.utc(startDate).format('YYYY-MM-DD'),
      date_to: dayjs.utc(endDate).format('YYYY-MM-DD'),
      aggregate_type: CreateEmployeeDemoReportRequest.AggregateTypeEnum.Range,
      include_grouped_companies: includeGroupedCompanies,
    };
    return this.companiesService.createEmployeeDemoReport(orgId, req).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCompany(orgId: string, id: string) {
    if (!id) return of(undefined);
    const req: FindCompanyRequest = {
      search_field: FindCompanyRequest.SearchFieldEnum.Id,
      search_value: id,
    };
    // Use an intermediate observable to catch the error and return undefined
    return this.companiesService.findCompany(orgId, req);
  }

  LocationToState = [
    { key: 'New York, New York, United States', state: 'NY' },
    { key: 'Greater Chicago Area', state: 'IL' },
    { key: 'New York City Metropolitan Area', state: 'NY' },
    { key: 'Houston, Texas, United States', state: 'TX' },
    { key: 'Los Angeles, California, United States', state: 'CA' },
    { key: 'San Francisco Bay Area', state: 'CA' },
    { key: 'Los Angeles Metropolitan Area', state: 'CA' },
    { key: 'Atlanta, Georgia, United States', state: 'GA' },
    { key: 'Austin, Texas, United States', state: 'TX' },
    { key: 'Seattle, Washington, United States', state: 'WA' },
    { key: 'Greater Boston', state: 'MA' },
    { key: 'San Diego, California, United States', state: 'CA' },
    { key: 'Washington, District of Columbia, United States', state: 'DC' },
    { key: 'San Francisco, California, United States', state: 'CA' },
    { key: 'Dallas-Fort Worth Metroplex', state: 'TX' },
    { key: 'Washington DC-Baltimore Area', state: 'DC' },
    { key: 'Denver, Colorado, United States', state: 'CO' },
    { key: 'Minneapolis, Minnesota, United States', state: 'MN' },
    { key: 'Miami, Florida, United States', state: 'FL' },
    { key: 'Brooklyn, New York, United States', state: 'NY' },
    { key: 'Dallas, Texas, United States', state: 'TX' },
    { key: 'Philadelphia, Pennsylvania, United States', state: 'PA' },
    { key: 'Atlanta Metropolitan Area', state: 'GA' },
    { key: 'Charlotte, North Carolina, United States', state: 'NC' },
    { key: 'Greater Philadelphia', state: 'PA' },
    { key: 'Boston, Massachusetts, United States', state: 'MA' },
    { key: 'Portland, Oregon, United States', state: 'OR' },
    { key: 'Greater Houston', state: 'TX' },
    { key: 'San Antonio, Texas, United States', state: 'TX' },
    { key: 'Phoenix, Arizona, United States', state: 'AZ' },
    { key: 'Greater Seattle Area', state: 'WA' },
    { key: 'San Jose, California, United States', state: 'CA' },
    { key: 'St Louis, Missouri, United States', state: 'MO' },
    { key: 'Orlando, Florida, United States', state: 'FL' },
    { key: 'Greater Minneapolis-St. Paul Area', state: 'MN' },
    { key: 'Las Vegas, Nevada, United States', state: 'NV' },
    { key: 'Indianapolis, Indiana, United States', state: 'IN' },
    { key: 'Cincinnati, Ohio, United States', state: 'OH' },
    { key: 'Columbus, Ohio, United States', state: 'OH' },
    { key: 'Tampa, Florida, United States', state: 'FL' },
    { key: 'Denver Metropolitan Area', state: 'CO' },
    { key: 'Detroit Metropolitan Area', state: 'MI' },
    { key: 'Greater Phoenix Area', state: 'AZ' },
    { key: 'Pittsburgh, Pennsylvania, United States', state: 'PA' },
    { key: 'St Paul, Minnesota, United States', state: 'MN' },
    { key: 'Nashville, Tennessee, United States', state: 'TN' },
    { key: 'Raleigh, North Carolina, United States', state: 'NC' },
    { key: 'Fort Lauderdale, Florida, United States', state: 'FL' },
    { key: 'Jacksonville, Florida, United States', state: 'FL' },
    { key: 'Milwaukee, Wisconsin, United States', state: 'WI' },
    { key: 'Miami-Fort Lauderdale Area', state: 'FL' },
    { key: 'Chicago, Illinois, United States', state: 'IL' },
    { key: 'Salt Lake City, Utah, United States', state: 'UT' },
    { key: 'Los Angeles County, California, United States', state: 'CA' },
    { key: 'Cleveland, Ohio, United States', state: 'OH' },
    { key: 'Fort Worth, Texas, United States', state: 'TX' },
    { key: 'Louisville, Kentucky, United States', state: 'KY' },
    { key: 'Kansas City, Missouri, United States', state: 'MO' },
    { key: 'Baltimore, Maryland, United States', state: 'MD' },
    { key: 'Sacramento, California, United States', state: 'CA' },
    { key: 'Richmond, Virginia, United States', state: 'VA' },
    { key: 'Omaha, Nebraska, United States', state: 'NE' },
    { key: 'Tucson, Arizona, United States', state: 'AZ' },
    { key: 'Greater Tampa Bay Area', state: 'FL' },
    { key: 'San Diego County, California, United States', state: 'CA' },
    { key: 'Greater St. Louis', state: 'MO' },
    { key: 'Orange County, California, United States', state: 'CA' },
    { key: 'Charlotte Metro', state: 'SC' },
    { key: 'Colorado Springs, Colorado, United States', state: 'CO' },
    { key: 'Austin, Texas Metropolitan Area', state: 'TX' },
    { key: 'Birmingham, Alabama, United States', state: 'AL' },
    { key: 'Scottsdale, Arizona, United States', state: 'AZ' },
    { key: 'Irvine, California, United States', state: 'CA' },
    { key: 'Memphis, Tennessee, United States', state: 'TN' },
    { key: 'Oklahoma City, Oklahoma, United States', state: 'OK' },
    { key: 'West Palm Beach, Florida, United States', state: 'FL' },
    { key: 'Raleigh-Durham-Chapel Hill Area', state: 'NC' },
    { key: 'Queens County, New York, United States', state: 'NY' },
    { key: 'Madison, Wisconsin, United States', state: 'MI' },
    { key: 'Portland, Oregon Metropolitan Area', state: 'OR' },
    { key: 'Bronx, New York, United States', state: 'NY' },
    { key: 'Rochester, New York, United States', state: 'NY' },
    { key: 'Buffalo, New York, United States', state: 'NY' },
    { key: 'Nashville Metropolitan Area', state: 'TN' },
    { key: 'Grand Rapids, Michigan, United States', state: 'MI' },
    { key: 'Greater Orlando', state: 'FL' },
    { key: 'Greater Cleveland', state: 'OH' },
    { key: 'Columbus, Ohio Metropolitan Area', state: 'OH' },
    { key: 'Plano, Texas, United States', state: 'TX' },
    { key: 'Albuquerque, New Mexico, United States', state: 'NM' },
    { key: 'Greater Pittsburgh Region', state: 'PA' },
    { key: 'Virginia Beach, Virginia, United States', state: 'VA' },
    { key: 'Oakland, California, United States', state: 'CA' },
    { key: 'Salt Lake City Metropolitan Area', state: 'UT' },
    { key: 'New Orleans, Louisiana, United States', state: 'LA' },
    { key: 'Kansas City Metropolitan Area', state: 'MO' },
    { key: 'Dayton, Ohio, United States', state: 'OH' },
    { key: 'Tulsa, Oklahoma, United States', state: 'OK' },
    { key: 'Arlington, Virginia, United States', state: 'VA' },
    { key: 'Knoxville, Tennessee, United States', state: 'TN' },
    { key: 'Greater Indianapolis', state: 'IN' },
    { key: 'Cincinnati Metropolitan Area', state: 'OH' },
    { key: 'Spring, Texas, United States', state: 'TX' },
    { key: 'Durham, North Carolina, United States', state: 'NC' },
    { key: 'Detroit, Michigan, United States', state: 'MI' },
    { key: 'Mesa, Arizona, United States', state: 'AZ' },
    { key: 'Greater Milwaukee', state: 'WI' },
    { key: 'Littleton, Colorado, United States', state: 'CO' },
    { key: 'Long Beach, California, United States', state: 'CA' },
    { key: 'St Petersburg, Florida, United States', state: 'FL' },
    { key: 'Marietta, Georgia, United States', state: 'GA' },
    { key: 'Greater New York City Area', state: 'NY' },
    { key: 'Baton Rouge, Louisiana, United States', state: 'LA' },
    { key: 'San Antonio, Texas Metropolitan Area', state: 'TX' },
    { key: 'Greensboro, North Carolina, United States', state: 'NC' },
    { key: 'Baltimore City County, Maryland, United States', state: 'MD' },
    { key: 'Hollywood, Florida, United States', state: 'FL' },
    { key: 'Boise, Idaho, United States', state: 'ID' },
    { key: 'Ann Arbor, Michigan, United States', state: 'MI' },
    { key: 'Greater Sacramento', state: 'CA' },
    { key: 'Aurora, Colorado, United States', state: 'CO' },
    { key: 'Boca Raton, Florida, United States', state: 'FL' },
    { key: 'Alpharetta, Georgia, United States', state: 'GA' },
    { key: 'Cambridge, Massachusetts, United States', state: 'MA' },
    { key: 'Fort Collins, Colorado, United States', state: 'CO' },
    { key: 'Lexington, Kentucky, United States', state: 'KY' },
    { key: 'Wichita, Kansas, United States', state: 'KS' },
    { key: 'El Paso, Texas, United States', state: 'TX' },
    { key: 'Overland Park, Kansas, United States', state: 'KS' },
    { key: 'Pompano Beach, Florida, United States', state: 'FL' },
    { key: 'New Alexandria, Virginia, United States', state: 'VA' },
    { key: 'Boulder, Colorado, United States', state: 'CO' },
    { key: 'Honolulu County, Hawaii, United States', state: 'HI' },
    { key: 'Arlington, Texas, United States', state: 'TX' },
    { key: 'Charleston, South Carolina, United States', state: 'SC' },
    { key: 'Tempe, Arizona, United States', state: 'AZ' },
    { key: 'Naples, Florida, United States', state: 'FL' },
    { key: 'Bellevue, Washington, United States', state: 'WA' },
    { key: 'Greenville, South Carolina, United States', state: 'SC' },
    { key: 'Chandler, Arizona, United States', state: 'AZ' },
    { key: 'Mountain View, California, United States', state: 'CA' },
    { key: 'Spokane, Washington, United States', state: 'WA' },
    { key: 'Las Vegas Metropolitan Area', state: 'NV' },
    { key: 'Lincoln, Nebraska, United States', state: 'NE' },
    { key: 'Tallahassee, Florida, United States', state: 'FL' },
    { key: 'Tacoma, Washington, United States', state: 'WA' },
    { key: 'Katy, Texas, United States', state: 'TX' },
    { key: 'Fresno, California, United States', state: 'CA' },
    { key: 'Reno, Nevada, United States', state: 'NV' },
    { key: 'Bakersfield, California, United States', state: 'CA' },
    { key: 'Lexington County, South Carolina, United States', state: 'SC' },
    { key: 'Irving, Texas, United States', state: 'TX' },
    { key: 'Sarasota, Florida, United States', state: 'FL' },
    { key: 'Fort Wayne, Indiana, United States', state: 'IN' },
    { key: 'Fremont, California, United States', state: 'CA' },
    { key: 'Santa Clara, California, United States', state: 'CA' },
    { key: 'Sunnyvale, California, United States', state: 'CA' },
    { key: 'Wilmington, Delaware, United States', state: 'DE' },
    { key: 'Staten Island, New York, United States', state: 'NY' },
    { key: 'Metro Jacksonville', state: 'FL' },
    { key: 'Silver Spring, Maryland, United States', state: 'MD' },
    { key: 'Winston-Salem, North Carolina, United States', state: 'NC' },
    { key: 'Riverside, California, United States', state: 'CA' },
    { key: 'Huntsville, Alabama, United States', state: 'AL' },
    { key: 'Jersey City, New Jersey, United States', state: 'NJ' },
    { key: 'Henderson, Nevada, United States', state: 'NV' },
    { key: 'Little Rock, Arkansas, United States', state: 'AR' },
    { key: 'Cary, North Carolina, United States', state: 'NC' },
    { key: 'Eugene, Oregon, United States', state: 'OR' },
    { key: 'Anchorage, Alaska, United States', state: 'AK' },
    { key: 'Greater Richmond Region', state: 'VA' },
    { key: 'Fort Myers, Florida, United States', state: 'FL' },
    { key: 'Akron, Ohio, United States', state: 'OH' },
    { key: 'Chattanooga, Tennessee, United States', state: 'TN' },
    { key: 'Wilmington, North Carolina, United States', state: 'NC' },
    { key: 'Melbourne, Florida, United States', state: 'FL' },
    { key: 'Berkeley, California, United States', state: 'CA' },
    { key: 'Greater Hartford', state: 'IL' },
    { key: 'Des Moines, Iowa, United States', state: 'IA' },
    { key: 'Anaheim, California, United States', state: 'CA' },
    { key: 'Gilbert, Arizona, United States', state: 'AZ' },
    { key: 'Gainesville, Florida, United States', state: 'FL' },
    { key: 'Naperville, Illinois, United States', state: 'IL' },
    { key: 'Providence, Rhode Island, United States', state: 'RI' },
    { key: 'Savannah, Georgia, United States', state: 'GA' },
    { key: 'Santa Monica, California, United States', state: 'CA' },
    { key: 'Huntington Beach, California, United States', state: 'CA' },
    { key: 'Frisco, Texas, United States', state: 'TX' },
    { key: 'Allentown, Pennsylvania, United States', state: 'PA' },
    { key: 'Oklahoma City Metropolitan Area', state: 'OK' },
    { key: 'Redmond, Washington, United States', state: 'WA' },
    { key: 'Provo, Utah, United States', state: 'UT' },
    { key: 'Rockville, Maryland, United States', state: 'MD' },
  ];

  JobFunctions = [
    'Banking and Wealth Management',
    'Business Management',
    'Consulting',
    'Education',
    'Engineering',
    'Finance and Administration',
    'Healthcare',
    'Human Resources',
    'Information Technology',
    'Legal',
    'Marketing and Product',
    'Operations',
    'Other',
    'Program and Project Management',
    'Publishing, Editorial and Reporting',
    'Quality',
    'Real Estate',
    'Risk, Safety, Compliance',
    'Sales and Support',
  ];
}
