import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FilterGroupFiltersInner,
  PersonSearchFilter,
  SearchPersonsDataRequest,
  SearchPersonsDataRequestFiltersInner,
  SearchService,
} from 'ldt-people-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ColumnCategory, ColumnType, DWColumn } from 'src/app/data-warehouse/dw-column';
import { FieldSelectorDialogComponent } from 'src/app/shared/field-selector-dialog/field-selector-dialog.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { PeopleColumns } from '../people-columns';
import { SearchFilter } from 'ldt-dw-reader-service-api';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { AddQuickBuildModalComponent } from './add-quick-build-modal/add-quick-build-modal.component';
import { QuickBuild, QuickBuildService } from '../quick-build.service';

import * as FileSaver from 'file-saver';
import * as dayjs from 'dayjs';
import { columnDefs, defaultColDef } from './ag-grid-options';
import { GridApi } from 'ag-grid-enterprise';
import { GridReadyEvent } from 'ag-grid-community';

export interface UIFilter {
  filter: PersonSearchFilter;
}

export interface UIFilterGroup {
  operator: 'and' | 'or';
  filters: Array<UIFilter | UIFilterGroup>;
  isJobsGroup?: boolean;
  jobsGroupType?: 'any' | 'active' | 'ended';
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  @Input()
  set setQuickBuild(data: string | undefined) {
    if (!data) {
      return;
    }

    const qb = this.qbService.deserializeQuickBuild(data);
    this.loadQuickBuild(qb);
  }

  orgId: string;
  showFilters: boolean = true;
  refreshing: boolean = false;
  countUpdating: boolean = false;
  searchCount: number = 0;
  downloading: boolean = false;
  collapseQuickBuilds: boolean = false;

  columnDefs = columnDefs;
  defaultColDef = defaultColDef;
  rowData: any[] = [];
  private samplePeopleGrid!: GridApi<any>;

  uiFilters: UIFilter[] = [];
  columns: DWColumn[] = PeopleColumns;
  categories: ColumnCategory[] = [
    {
      name: 'personal',
      displayName: 'Personal',
      description: 'Personal information',
      displayColumn: 1,
    },
    {
      name: 'jobs',
      displayName: 'Job Experience',
      description: 'Job characteristics, such as company, title, and dates of employment',
      displayColumn: 3,
    },
    {
      name: 'education',
      displayName: 'Education',
      description: 'Information about education',
      displayColumn: 1,
    },
    {
      name: 'metadata',
      displayName: 'Timestamps',
      description: 'Timestamps for this record',
      displayColumn: 1,
    },
  ];

  rootFilterGroup: UIFilterGroup = {
    operator: 'and',
    filters: [],
  };

  constructor(
    private dialog: MatDialog,
    private notify: NotificationService,
    private peopleService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private qbService: QuickBuildService
  ) {}

  ngOnInit() {
    let orgId = this.route.parent?.snapshot.paramMap.get('orgId');
    if (!orgId) {
      orgId = this.authService.getSelectedOrgIdValue;
      if (!orgId) {
        this.notify.error('Invalid path');
        this.router.navigateByUrl('/main');
      }
    }
    this.orgId = orgId;
    if (!this.loadFiltersFromQuerystring()) {
      this.loadFiltersFromLocalStorage();
    }
  }

  /**
   * Opens a modal dialog to select filter based on the given category, then updates filter arrays to add this filter.
   */
  showSelectorModal(isJobsGroup: boolean): Promise<string | undefined> {
    return new Promise((resolve) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '1200px';
      dialogConfig.height = '80%';

      try {
        // if isJobsGroup is true, only show filters from the 'jobs' category
        if (isJobsGroup) {
          const category = this.categories.filter((c) => c.name === 'jobs');
          const fields = this.columns.filter((c) => c.group === 'jobs');
          dialogConfig.data = {
            categories: category,
            fields: fields,
          };
        } else {
          dialogConfig.data = { categories: this.categories, fields: this.columns };
        }
        const dialogRef = this.dialog.open(FieldSelectorDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((data) => {
          resolve(data);
        });
      } catch (err) {
        console.error(err);
        this.notify.error('Error adding filter. Please try again later.');
        resolve(undefined);
      }
    });
  }

  /**
   * Handles data (single filter name) returned from the field selector dialog and adds the selected filter to the appropriate group.
   * @param filterName - filterName to be handled, e.g. 'position.company.name'.
   * @param groupComponent - the group component to which the filter should be added.
   */
  handleDataFromDialog(filterName: string, groupComponent: UIFilterGroup): void {
    const isRootGroup = groupComponent === this.rootFilterGroup;
    const foundFilter = this.columns.find((f) => f.name === filterName);

    if (foundFilter) {
      const newFilter: UIFilter = {
        filter: {
          field: foundFilter.name,
          type: SearchFilter.TypeEnum.Must,
          match_type: this.matchTypesAsObject(foundFilter.name)[0],
        },
      };

      // Check if the groupComponent is a 'jobs' group; if yes, add the filter directly to this group
      if (groupComponent.isJobsGroup) {
        groupComponent.filters.push(newFilter);
      } else {
        // Check if the filter belongs to the 'jobs' category
        const isPastJobsFilter = foundFilter.group === 'jobs';

        // Get the filters array based on the type of groupComponent
        const groupFilters = this.getFiltersArray(groupComponent);

        if (isPastJobsFilter) {
          // Check if there is an existing 'jobs' group within this group
          let pastJobsGroup = this.findPastJobsGroup(groupFilters);

          // if current group is empty, add new filter directly to this group and convert it to a 'jobs' group
          if (groupFilters.length === 0 && !isRootGroup) {
            groupFilters.push(newFilter);
            groupComponent.isJobsGroup = true;
            groupComponent.jobsGroupType = 'any';
            return;
          }

          pastJobsGroup = { operator: 'and', filters: [newFilter], isJobsGroup: true };
          groupFilters.push(pastJobsGroup);
        }

        // Add to the normal group
        else {
          groupComponent.filters.push(newFilter);
        }
      }
    } else {
      console.error(`Filter ${filterName} does not exist.`);
    }
  }

  /**
   * Helper method to find an existing 'jobs' group in the given filters array.
   */
  private findPastJobsGroup(filters: Array<UIFilter | UIFilterGroup>): UIFilterGroup | undefined {
    return filters.find(
      (g) => this.isFilterGroup(g) && (g as UIFilterGroup).isJobsGroup && g.operator === 'and' // Optional: Add more checks to ensure the correct group
    ) as UIFilterGroup;
  }

  isFilterGroup(item: UIFilter | UIFilterGroup): item is UIFilterGroup {
    return (item as UIFilterGroup).operator !== undefined;
  }

  /**
   * Helper method to get the filters array from a FilterGroup or FilterGroupComponent.
   */
  private getFiltersArray(
    groupComponent: FilterGroupComponent | UIFilterGroup
  ): Array<UIFilter | UIFilterGroup> {
    return groupComponent instanceof FilterGroupComponent
      ? groupComponent.group.filters
      : groupComponent.filters;
  }

  handleFilterAdded(event: { component: FilterGroupComponent; isJobsGroup: boolean }): void {
    if (!event.component) {
      console.error('Group is undefined, cannot add filter.');
      return;
    }

    // Open the filter selection dialog and pass the `isJobsGroup` value
    this.showSelectorModal(event.isJobsGroup).then((selectedFilterName: string | undefined) => {
      if (selectedFilterName) {
        this.handleDataFromDialog(selectedFilterName, event.component.group);
      }
    });
  }

  deleteAllFilters() {
    this.rootFilterGroup.filters = [];
  }

  rootFilterGroupToAPIFilters(): SearchPersonsDataRequestFiltersInner {
    const topFilter: SearchPersonsDataRequestFiltersInner = {
      filters: this.filterGroupToAPIFilters(this.rootFilterGroup),
      operator: this.rootFilterGroup.operator,
    };

    return topFilter;
  }

  filterGroupToAPIFilters(group: UIFilterGroup): FilterGroupFiltersInner[] {
    const filters: FilterGroupFiltersInner[] = [];

    if (group.filters) {
      group.filters.forEach((filter, idx) => {
        if ('filters' in filter) {
          // This is a FilterGroup
          filter = filter as UIFilterGroup;

          // If this is a jobs group, we handle all the filters without recursion so we can
          // modify them in special ways
          if (filter.isJobsGroup) {
            const groupFilters: FilterGroupFiltersInner[] = [];
            const jobsGroupType = filter.jobsGroupType || 'any';

            // If they only want ended jobs, add a filter for that
            if (jobsGroupType === 'ended') {
              groupFilters.push({
                field: 'jobs.ended_at',
                type: SearchFilter.TypeEnum.Must,
                match_type: SearchFilter.MatchTypeEnum.Exists,
              });
            }

            filter.filters.forEach((f) => {
              // All filters in job groups are UI filters. Get a copy for modification...
              const apiFilter = JSON.parse(JSON.stringify(f)) as UIFilter;

              // If searching active jobs, use the position field instead of the jobs field
              if (jobsGroupType === 'active') {
                apiFilter.filter.field = apiFilter.filter.field!.replace(/^jobs/, 'position');
              }

              if (apiFilter.filter.field?.endsWith('_search')) {
                const searchFilters = this.generateCompanySearchAPIFilters(apiFilter);
                if (searchFilters) {
                  groupFilters.push(searchFilters);
                }
              } else {
                groupFilters.push(apiFilter.filter);
              }
            });

            filters.push({
              operator: 'and',
              filters: groupFilters,
            });
          } else {
            filters.push({
              operator: filter.operator,
              filters: this.filterGroupToAPIFilters(filter),
            });
          }
        } else {
          // This is a UIFilter
          filter = filter as UIFilter;

          // Change _search filters to search for company ID
          if (filter.filter.field?.endsWith('_search')) {
            const searchFilters = this.generateCompanySearchAPIFilters(filter);
            if (searchFilters) {
              filters.push(searchFilters);
            }
          } else {
            filters.push(filter.filter);
          }
        }
      });
    }
    return filters;
  }

  // Given a UIFilter that represents a company search, generates the nested filters to find
  // matching companies and company groups
  generateCompanySearchAPIFilters(filter: UIFilter): FilterGroupFiltersInner | null {
    // If this is a company search field, we need to create the API filters for the companies and groups
    if (!filter.filter.string_values) return null;

    const groupValues = filter.filter.string_values.filter((v) => v.endsWith('-group')) || [];

    // We add a filter the company ID for all provided string values. This ensures we capture the actual
    // parents
    const filtersForCompany: FilterGroupFiltersInner[] = [];
    if (filter.filter.string_values.length > 0) {
      filtersForCompany.push({
        field: filter.filter.field!.replace('_search', '.company.id'),
        type: SearchFilter.TypeEnum.Must,
        match_type: filter.filter.match_type,
        string_values: filter.filter.string_values.map((v) => v.replace('-group', '')),
      });
    }

    // For any grouped companies, we add another filter for the group ID
    if (groupValues.length > 0) {
      filtersForCompany.push({
        field: filter.filter.field!.replace('_search', '.company.group_id'),
        type: SearchFilter.TypeEnum.Must,
        match_type: filter.filter.match_type,
        string_values: groupValues.map((v) => v.replace('-group', '')),
      });
    }

    // Match on either the provided company, or the parent companies if any were provided
    return {
      operator: 'or',
      filters: filtersForCompany,
    };
  }

  // Updates the current URL querystring and browser cache so that the URL can be copied and shared with others and so
  // that navigating back to this page loads the same search. Uses the quickbuild serializer to get a string representation of the search params
  updateUrl(): void {
    // The id and name aren't needed in this case, so we just use placeholders
    const qb: QuickBuild = {
      id: 0,
      name: '',
      rootFilterGroup: this.rootFilterGroup,
    };
    const settings = this.qbService.serializeQuickBuild(qb);
    const encodedFilter = encodeURIComponent(settings);

    // Save to local storage and the URL querystring
    localStorage.setItem('person-filter-params', settings);
    if (
      !this.route.snapshot.queryParams.settings ||
      this.route.snapshot.queryParams.settings !== encodedFilter
    ) {
      const url = '/' + this.orgId + '/people/filters?settings=' + encodedFilter;
      this.router.navigateByUrl(url);
    }
  }

  // Loads a serialized set of filters from the querystring into the page. If the querystring didn't exist or was malformed
  // this returns false
  loadFiltersFromQuerystring(): boolean {
    const { settings } = this.route.snapshot.queryParams;
    if (!settings) {
      return false;
    }

    try {
      const serializedQB: string = decodeURIComponent(settings);
      this.loadSerializedQuickBuild(serializedQB);
      this.collapseQuickBuilds = true;
      return true;
    } catch (error) {
      // Clear the querystring if it was malformed
      this.updateUrl();
    }

    return false;
  }

  // Loads a serialized set of filters from localstorage into the page, if they exist
  loadFiltersFromLocalStorage(): void {
    try {
      const savedParams: any = localStorage.getItem('person-filter-params');
      if (savedParams) {
        this.loadSerializedQuickBuild(savedParams);
      }
    } catch (error) {
      console.error('Error getting data from localstorage:', error);
    }
  }

  // Loads a serialized quickbuild string into the page
  loadSerializedQuickBuild(serializedQuickBuild: string): void {
    const qb = this.qbService.deserializeQuickBuild(serializedQuickBuild);
    this.loadQuickBuild(qb);
  }

  runSearch() {
    this.updateUrl();
    this.setLoading(true);

    const req: SearchPersonsDataRequest = {
      filters: [this.rootFilterGroupToAPIFilters()],
      size: 100,
    };

    this.peopleService.searchPersonsData(this.orgId, req).subscribe({
      next: (data) => {
        this.searchCount = data.count || 0;
        this.rowData = data.people || [];
        this.setLoading(false);
      },
      error: (err) => {
        console.error('Error searching for people:', err);
        this.notify.error('Error searching for people');
        this.setLoading(false);
      },
    });
  }

  setLoading(loading: boolean) {
    if (loading) {
      this.refreshing = true;
      this.countUpdating = true;
      return;
    }

    this.refreshing = false;
    this.countUpdating = false;
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.samplePeopleGrid = params.api;
  }

  download() {
    if (this.searchCount > 10000) {
      this.notify.error(
        'You can only download up to 10,000 records at a time. Please narrow your search.'
      );
      return;
    }

    this.downloading = true;

    const req: SearchPersonsDataRequest = {
      filters: [this.rootFilterGroupToAPIFilters()],
      size: 0,
    };

    this.peopleService.downloadPersonsData(this.orgId, req).subscribe({
      next: (response) => {
        const csvData = response as unknown as string; // type assertion to avoid using 'any'
        const data = new Blob([csvData], { type: 'text/csv' });
        FileSaver.saveAs(data, 'livedata-download-' + dayjs().format('YYYYMMDD-HHmmss') + '.csv');
        this.downloading = false;
      },
      error: (err) => {
        console.error('Error searching for people:', err);
        this.notify.error('Error searching for people');
        this.refreshing = false;
        this.countUpdating = false;
      },
    });
  }

  copyFiltersToClipboard() {
    const filters = [this.rootFilterGroupToAPIFilters()];

    const filtersString = JSON.stringify(filters, null, 2);
    navigator.clipboard.writeText(filtersString).then(
      () => {
        this.notify.success('Filters copied to clipboard');
      },
      () => {
        this.notify.error('Error copying filters to clipboard');
      }
    );
  }

  showAddQuickBuildDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      filterNames: this.uiFilters.map((f) => f.filter.field),
    };
    const dialogRef = this.dialog.open(AddQuickBuildModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const qb: QuickBuild = {
          id: 0,
          name: data.name,
          rootFilterGroup: this.rootFilterGroup,
        };
        this.qbService.createQuickBuild(qb);
      }
    });
  }

  /**
   * Helper methods
   */

  focusInput(inputField: HTMLInputElement) {
    inputField.focus();
  }

  loadQuickBuild(qb: QuickBuild) {
    this.rootFilterGroup = qb.rootFilterGroup || [];
    this.runSearch();
  }

  /**
   * Returns an array of match types as objects for the specified field.
   * @param field - The field for which to retrieve the match types.
   * @returns An array of match types as objects.
   */
  matchTypesAsObject(field: string): SearchFilter.MatchTypeEnum[] {
    let values = Object.values(SearchFilter.MatchTypeEnum);
    const col = this.columns.find((c) => c.name === field);

    // Date fields don't use `exact` match type
    if (col && col.type === ColumnType.date) {
      values = values.filter((v) => v !== SearchFilter.MatchTypeEnum.Exact);
    }

    // Enum and boolean fields don't use `fuzzy` match type
    if (
      col &&
      (col.type === ColumnType.jobfunction ||
        col.type === ColumnType.joblevel ||
        col.type === ColumnType.boolean)
    ) {
      values = values.filter((v) => v !== SearchFilter.MatchTypeEnum.Fuzzy);
    }

    // Company search defaults to exact
    if (col && col.type === ColumnType.companySearch) {
      values = [SearchFilter.MatchTypeEnum.Exact];
    }

    return values;
  }

  addBadgeToFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters[filterIndex] = event.filter;
    }
  }

  removeBadgeFromFilter(event: { filter: UIFilter }) {
    const filterIndex = this.uiFilters.findIndex((f) => f === event.filter);
    if (filterIndex !== -1) {
      this.uiFilters[filterIndex] = event.filter;
    }
  }

  // FILTER CHIPS CODE
  toggleFiltersContent() {
    this.showFilters = !this.showFilters;
  }

  removeFilterChip(targetFilter: UIFilter) {
    this.removeFilterRecursive(this.rootFilterGroup, targetFilter);
  }

  private isUIFilter(filter: UIFilter | UIFilterGroup): filter is UIFilter {
    return 'field' in filter && 'filter' in filter;
  }

  private removeFilterRecursive(group: UIFilterGroup, targetFilter: UIFilter): boolean {
    for (let i = 0; i < group.filters.length; i++) {
      const filter = group.filters[i];

      if (this.isUIFilter(filter) && filter === targetFilter) {
        // Remove the target filter from the filters array
        group.filters.splice(i, 1);
        return true;
      } else if (!this.isUIFilter(filter)) {
        // Recursively search in nested FilterGroups
        const found = this.removeFilterRecursive(filter, targetFilter);
        if (found) {
          // If the nested FilterGroup becomes empty after removal, optionally remove it
          if (filter.filters.length === 0) {
            group.filters.splice(i, 1);
          }
          return true;
        }
      }
    }
    return false;
  }
  // END FILTER CHIPS CODE
}
