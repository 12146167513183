import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { CustomerSubscriptionsService, Subscription } from 'ldt-billing-service-api';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class SettingsBillingComponent implements OnInit {
  @Input() orgId: string;
  @Input() canEdit: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private subscriptionService: CustomerSubscriptionsService,
    private auth: AuthService,
    private router: Router
  ) {}

  annual: boolean = true;
  uiSelector: boolean = true;
  loading: boolean = true;

  subscription: Subscription;
  fromPurchase = false;

  ngOnInit(): void {
    // See if we're coming back from Stripe
    const params = this.route.snapshot.queryParams;

    if ('stripeResults' in params) {
      const isSuccess = params['stripeResults'] == 'success';
      const isMBSubscription =
        'priceId' in params && params['priceId'] == environment.moneyballSubscriptionPriceId;

      if (!isSuccess) {
        this.notify.error(
          'There was a problem processing your subscription. Please try again later.'
        );
        return;
      }

      if (isMBSubscription) {
        // MB Subscription purchased
        this.notify.success('Thanks for your subscription! Please wait....');

        timer(1 * 1000).subscribe(() => {
          // Refresh the session to get the license info
          this.auth.refreshSession().subscribe(() => {
            if ((this.auth.getSelectedOrgValue as any).roleSettings?.licenses?.moneyball === true) {
              this.notify.success('Welcome to Moneyball!');
              this.router.navigate(['/' + this.orgId + '/moneyball/battle']);
            } else {
              this.notify.success('Assign your licenses to users to start using Moneyball');
              this.router.navigate(['/' + this.orgId + '/team']);
            }
          });
        });

        return;
      } else {
        this.fromPurchase = true;
        this.notify.success('Thanks for your subscription! Welcome to Live Data!');

        timer(3 * 1000).subscribe(() => {
          // Refresh the session to get the new limits
          this.auth.refreshSession().subscribe(() => {
            // nothing to do
          });
          if (!this.subscription) this.getSubscription();
        });
      }
    }

    this.getSubscription();
  }

  getSubscription() {
    this.subscriptionService.getOrgSubscription(this.orgId).subscribe({
      next: (res) => {
        this.subscription = res;
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        if (err.status === 404) {
          // No current subscription
        } else {
          this.notify.error('Oops. Unable to get your current plan. Please try again later.');
        }
      },
    });
  }
}
