import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  MatLegacyDialog as LegacyMatDialog,
  MatLegacyDialogConfig as LegacyMatDialogConfig,
} from '@angular/material/legacy-dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ColDef, GridApi } from 'ag-grid-community';
import { Org, APIKeysService, APIKey, CreateApiKeyRequest } from 'ldt-identity-service-api';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AkCreateDialogComponent } from './ak-create-dialog/ak-create-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { BtnCellRenderer } from 'src/app/shared/ag-grid-button/button-cell-renderer.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-team-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss'],
  providers: [DatePipe],
})
export class ApiKeysComponent implements OnInit {
  @Input() org: Org;
  @Input() canAdmin: boolean;

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  columnDefs: ColDef[] = [
    {
      field: 'id',
      width: 40,
      maxWidth: 40,
      checkboxSelection: true,
      sortable: false,
      filter: false,
      cellRenderer: (params: any) => {
        return '';
      },
    },
    {
      field: 'key',
      maxWidth: 100,
      headerName: '',
      sortable: false,
      filter: false,
      cellRenderer: BtnCellRenderer,
      cellRendererParams: {
        onClick: this.copyKeyToClipboard.bind(this),
        label: 'Copy',
        buttonType: 'mat-stroked-button',
        display: 'compact',
      },
    },
    {
      field: 'key_shorthand',
      headerName: 'Key Identifier',
      maxWidth: 250,
    },
    {
      field: 'name',
    },
    { field: 'description' },
    {
      field: 'created_at',
      headerName: 'Created (UTC)',
      sort: 'desc',
      headerTooltip: 'Time the API Key was created',
      valueFormatter: (params: any) => {
        return this.datePipe.transform(params.value, 'yyyy-MM-dd h:mm a', 'UTC') || '';
      },
    },
  ];
  rowData: APIKey[];
  tooltipShowDelay = 200;

  private gridApi: GridApi;
  private orgId: string = '';

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      });
    });
  }

  refreshing: boolean = true;

  constructor(
    private akService: APIKeysService,
    private route: ActivatedRoute,
    private notify: NotificationService,
    public dialog: MatDialog,
    private legacyDialog: LegacyMatDialog,
    private datePipe: DatePipe,
    public darkModeService: DarkModeService,
    private clipboard: Clipboard
  ) {
    this.orgId = this.route.snapshot.paramMap.get('orgId') || '';
  }

  ngOnInit() {
    this.refreshApiKeys();
  }

  refreshApiKeys() {
    this.refreshing = true;
    this.akService.listApiKeys(this.org.id).subscribe({
      next: (r) => {
        this.rowData = r;
        this.refreshing = false;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
        this.rowData = [];
        this.refreshing = false;
      },
    });
  }

  rowsSelected: boolean = false;
  onSelectionChanged($event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  // delete selected api keys
  removeSelected() {
    var ids: any = this.gridApi.getSelectedRows().map((d: APIKey) => d.id);
    const confirmText = ids.length === 1 ? 'this API Key' : 'these API Keys';
    const confirmDialog = this.legacyDialog.open(DeleteConfirmationComponent, {
      width: '500px',
      data: {
        title: 'Confirm API Key Deletion',
        message: `Are you sure you want to delete ${confirmText}? This action is unrecoverable!`,
      },
    });

    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        // able to delete multiple api keys at once in parallel and wait for all to finish
        forkJoin(ids.map((id: any) => this.akService.deleteApiKey(this.orgId, id))).subscribe({
          next: () => {
            this.gridApi.deselectAll();
            this.refreshApiKeys();
            const successMessage =
              ids.length === 1 ? 'API Key successfully deleted.' : 'API Keys successfully deleted.';
            this.notify.success(successMessage);
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }

  showCreateDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.panelClass = 'full-w-mobile-dialog'; // custom class for styling

    const dialogRef = this.dialog.open(AkCreateDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let req: CreateApiKeyRequest = {
          name: data.name,
          description: data.description,
        };

        this.akService.createApiKey(this.org.id, req).subscribe({
          next: (key) => {
            this.clipboard.copy(key.key);
            this.refreshApiKeys();
            this.notify.success('API Key successfully created and copied to clipboard.');
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }

  copyKeyToClipboard(e: any) {
    let key: APIKey = e.rowData;
    this.clipboard.copy(key.key);
    this.notify.success('Copied to clipboard!');
  }
}
