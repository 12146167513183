<div
  class="notification-container"
  *ngIf="$isMxMode | async"
  matTooltip="During maintenance, no contacts can be added to ledgers and existing contacts may not be updated."
  [matTooltipHideDelay]="1000">
  <mat-icon aria-hidden="false">warning</mat-icon>Ledgers are currently undergoing maintenance
</div>

<!-- header -->
<header
  #logo
  class="logo"
  *ngIf="$isLoggedIn | async;"
  class="tw-bg-gray-800 tw-px-5 tw-pt-3 tw-pb-2 tw-flex tw-border-gray-600 tw-border-b tw-border tw-border-solid tw-flex-wrap tw-gap-x-6 md:tw-justify-between tw-gap-y-2">
  <div class="tw-flex tw-items-center">
    <!-- toggle hamburger menu btn -->
    <button
      id="toggleSidebar"
      aria-expanded="true"
      aria-controls="sidebar"
      (click)="toggleSideNav()"
      class="tw-mr-4 tw-text-gray-600 tw-rounded tw-cursor-pointer lg:tw-inline hover:tw-text-gray-900 hover:tw-bg-gray-100 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-700">
      <svg
        class="tw-w-5 tw-h-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 12">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h14M1 6h14M1 11h7" />
      </svg>
    </button>

    <a href="#" class="tw-flex tw-items-center">
      <img
        src="https://assets-global.website-files.com/65705b930fb2e670f3206741/657cb4efdeed95030646ede2_Live%20Data%20Logo.svg"
        alt="logo"
        class="tw-w-36" />
    </a>
  </div>

  <!-- subscribe-cto -->
  <div
    *ngIf="showSubscribeCTA"
    class="tw-flex-1 tw-shrink-0 tw-text-white tw-font-semibold tw-text-sm md:tw-text-base tw-flex tw-items-center tw-justify-center tw-gap-4
    tw-w-full sm:tw-w-auto tw-mt-2 sm:tw-mt-0"
    (click)="showSubscriptionDialog()">
    <div>
      Only <span class="tw-font-bold">{{mbDaysRemaining}}</span> days left in your Moneyball trial!
    </div>

    <button
      class="tw-shrink-0 tw-relative tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden tw-text-sm tw-font-medium tw-text-gray-900 tw-rounded-md tw-group tw-bg-gradient-to-br tw-from-purple-600 tw-to-blue-600 group-hover:tw-from-purple-600 group-hover:tw-to-blue-500 hover:tw-text-white dark:tw-text-white focus:tw-outline-none focus:tw-ring-blue-800 tw-p-0.5">
      <span
        class="tw-relative tw-px-5 tw-py-2 tw-transition-all ease-in duration-75 tw-bg-gray-900 tw-rounded-md group-hover:tw-bg-opacity-0">
        Subscribe
      </span>
    </button>
  </div>
</header>

<mat-sidenav-container *ngIf="$isLoggedIn | async; else elseBlock" class="tw-bg-gray-800">
  <mat-sidenav #sidenav="matSidenav" mode="side" [opened]="!isSmallScreen" disableClose>
    <button
      *ngIf="($orgCapabilities | async).includes('ledger') && ($isOrgActive | async)"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/ledgers'">
      <mat-icon>fact_check</mat-icon><span>Ledgers</span>
    </button>
    <button
      *ngIf="($orgCapabilities | async).includes('moneyball') && ($isMBLicensed | async)"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="selectedOrg + '/moneyball'">
      <mat-icon>query_stats</mat-icon><span>Moneyball</span>
    </button>
    <button
      *ngIf="($orgCapabilities | async).includes('moneyball') && ($isMBLicensed | async)"
      mat-button
      class="sub-menu-btn menu-button tw-ml-6 tw-w-[87%]"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/moneyball/battle'">
      <mat-icon>compare_arrows</mat-icon><span class="tw-text-sm">Moneyball Battle</span>
    </button>
    <button
      *ngIf="!($orgCapabilities | async).includes('moneyball') || !($isMBLicensed | async)"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/moneyball/sample'"
      matTooltip="Requires additional subscription"
      matTooltipPosition="right">
      <span class="tw-flex tw-items-center">
        <mat-icon>query_stats</mat-icon>
        <span class="tw-text-sm tw-flex tw-items-center">
          Moneyball
          <mat-icon class="tw-w-[14px] tw-h-[14px] tw-ml-2"> lock </mat-icon>
        </span>
      </span>
    </button>
    <button
      *ngIf="($orgCapabilities | async).includes('people') && ($isOrgActive | async)"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/people/details'">
      <mat-icon>person</mat-icon><span>People</span>
    </button>
    <button
      *ngIf="($orgCapabilities | async).includes('clean') && ($isOrgActive | async)"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/jobs'">
      <mat-icon>receipt_long</mat-icon><span>Jobs</span>
    </button>
    <button
      *ngIf="orgs.length > 0 && ($isOrgActive | async)"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/integrations'">
      <mat-icon>sync_alt</mat-icon><span>Integrations</span>
    </button>
    <button
      *ngIf="$isOrgAdmin | async"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/team'">
      <mat-icon>groups</mat-icon><span>Team</span>
      <button
        (click)="showInviteDialog($event)"
        class="tw-bg-white tw-text-indigo-600 tw-text-xs tw-font-semibold tw-rounded-md tw-shadow-sm tw-transition hover:tw-bg-indigo-100 hover:tw-text-indigo-700 focus:tw-bg-indigo-100 focus:tw-text-indigo-700 tw-ml-8 tw-px-2 tw-py-0.5">
        + Invite
      </button>
    </button>
    <button
      *ngIf="$isOrgAdmin | async"
      mat-button
      class="menu-button"
      routerLinkActive="active-nav"
      [routerLink]="selectedOrg + '/settings'">
      <mat-icon>money</mat-icon><span>Billing</span>
    </button>

    <!-- Admin menu -->
    <div *ngIf="$showAdmin | async">
      <button mat-button class="menu-button admin-menu" [matMenuTriggerFor]="adminmenu">
        <mat-icon>engineering</mat-icon>
        <span>Administration</span>
        <svg
          aria-hidden="true"
          class="tw-w-6 tw-h-6 tw-ml-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
      <mat-menu #adminmenu="matMenu">
        <a mat-menu-item *ngFor="let item of adminRoutes" [routerLink]="item.path">{{item.name}}</a>
        <a mat-menu-item (click)="checkForUpdate()">Check UI Updates</a>
        <a mat-menu-item (click)="triggerRefresh()">Refresh Session</a>
      </mat-menu>
    </div>

    <!-- Help dropdown menu -->
    <div class="account-menu tw-space-y-2 tw-border-gray-600 tw-border-t tw-pt-3">
      <button mat-button class="menu-button" [matMenuTriggerFor]="menu">
        <mat-icon>help</mat-icon>
        <span>Help</span>
        <svg
          aria-hidden="true"
          class="tw-w-6 tw-h-6 tw-ml-20"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
        </svg>
      </button>

      <mat-menu #menu="matMenu">
        <a mat-menu-item href="https://docs.gotlivedata.com" target="_blank">Documentation</a>
        <button mat-menu-item (click)="showSupportWindow()">Get Support</button>
        <div mat-menu-item disabled>Version: {{appVersion}}</div>
      </mat-menu>
    </div>

    <!-- My Account dropdown menu -->
    <button mat-button class="menu-button" [matMenuTriggerFor]="usermenu">
      <mat-icon>person</mat-icon>
      <span>My Account</span>
      <svg
        aria-hidden="true"
        class="tw-w-6 tw-h-6 tw-ml-8"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
      </svg>
    </button>

    <mat-menu #usermenu="matMenu">
      <a mat-menu-item routerLink="invitations">My Invitations</a>
      <!-- <a mat-menu-item routerLink="account">Edit Account</a> -->
      <a mat-menu-item (click)="logout()">Logout</a>
    </mat-menu>

    <!-- Org name -->
    <div
      *ngIf="orgs.length > 0"
      class="org-left-nav-container tw-text-white tw-bg-gray-900 tw-border tw-border-solid tw-fixed tw-bottom-0 tw-mb-8 tw-p-3">
      <span class="tw-text-sm">Organization:</span>
      <span *ngIf="orgs.length == 1" class="org-name">{{orgs[0].name}}</span>
      <mat-select
        *ngIf="orgs.length > 1"
        (selectionChange)="onOrgSelected($event)"
        [(value)]="selectedOrg"
        class="tw-mt-2">
        <mat-option *ngFor="let org of orgs" [value]="org.id">
          {{org.name}}
        </mat-option>
      </mat-select>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container tw-pl-6">
      <router-outlet></router-outlet>
    </div>
    <footer class="tw-shadow tw-bg-gray-800 tw-antialiased tw-pt-2">
      <p class="tw-text-xs tw-text-center tw-text-gray-400 tw-my-0">
        &copy; 2024 Live Data Technologies, Inc
      </p>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
<hotkeys-cheatsheet *ngIf="$showAdmin | async" title="Live Data admin hotkeys"></hotkeys-cheatsheet>
<ng-template #elseBlock>
  <router-outlet></router-outlet>
</ng-template>
