export enum SavedListType {
  People = 'people',
  Companies = 'companies',
  Jobs = 'jobs',
  Lists = 'lists',
}

export interface SavedListCompanyType {
  id: string;
  group?: boolean;
}

export interface SavedList {
  itemType: SavedListType;
  id: string;
  name: string;
  description?: string;
  createdAt: string;
  createdBy: string;
  items: SavedListCompanyType[];
}
